"use client";

import Image from "next/image";
import React from "react";

const Banner = ({
  src,
  alt,
  blurURL,
  height = 600,
  width = 1600,
  className,
  imageClassName,
  parentClassName,
  children,
  widgetIndex,
}: {
  src: string;
  alt: string;
  blurURL: string;
  height: number;
  width: number;
  className?: string;
  imageClassName?: string;
  parentClassName?: string;
  children?: React.ReactNode;
  widgetIndex: number;
}) => {
  const DEFAULT_BLUR_DATA_URL =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

  return (
    <section className={`${parentClassName ? parentClassName : ""}`}>
      <div className={`full-width-banner ${className ? className : ""}`}>
        {children}
        <Image
          alt={alt}
          src={src}
          width={width}
          height={height}
          blurDataURL={blurURL || DEFAULT_BLUR_DATA_URL}
          placeholder="blur"
          className={`w-100 h-100 ${imageClassName ? imageClassName : ""}`}
        />
      </div>
    </section>
  );
};

export { Banner };
