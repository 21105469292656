"use client";

import React, { useEffect } from "react";
import {
  Button,
  CustomSelect,
  Loader,
  ProgressBar,
  Ratings,
} from "@/components/core-components";
import Image from "next/image";
import {
  displayValue,
  formatDateTime,
  getLastPathSegment,
  IReview,
  IReviewData,
  IReviewStatistics,
  STATUSES,
  to2Decimal,
} from "@/utils";
import moment from "moment";
import {
  fetchProductReviews,
  setFilters,
  setQuickView,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { Pagination } from "@/components/Pagination";
import { usePathname } from "next/navigation";

const Comment = ({
  name,
  since,
  rating,
  comment,
  verified = false,
  contents,
  created_at,
}: {
  name: string;
  since: string;
  rating: number;
  comment: string;
  verified: boolean;
  created_at: string;
  contents: {
    media_url: string;
    media_type: string;
  }[];
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className="single-comment d-flex justify-content-between w-100">
      <div className="user d-flex justify-content-between">
        <div className="thumb text-left">
          <h6 className="text-primary custom-pointer">{name}</h6>
          {verified && (
            <span className="stock-status out-stock bg-green rounded-2 p-0 px-1 text-white mt-2">
              Verified
            </span>
          )}
          <p className="font-xxs">{`Since ${moment(since).get("year")}`}</p>
        </div>
        <div className="desc">
          <Ratings rating={rating} />
          <p>{comment}</p>
          <div className="d-flex justify-content-between mb-10">
            <div className="d-flex align-items-center">
              <p className="font-xs mr-30">{formatDateTime(created_at)}</p>
            </div>
          </div>

          {contents && contents.length > 0 ? (
            <div className="d-flex flex-wrap">
              {contents.map((content, index) => (
                <div
                  key={index}
                  className="review-media-div mr-10"
                  onClick={() =>
                    dispatch(
                      setQuickView({
                        state: true,
                        type: "review-detail",
                        data: {
                          content: content,
                        },
                      })
                    )
                  }
                >
                  {content.media_type === "image" ? (
                    <Image
                      width={100}
                      height={100}
                      className="h-100 w-100"
                      src={content.media_url}
                      alt={`Thumbnail ${index}`}
                    />
                  ) : (
                    <video>
                      <source src={content.media_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const ReviewList = ({
  reviews,
  product_id,
  isModal,
  showSeeMore,
}: {
  reviews: IReviewData;
  product_id?: number;
  isModal: boolean;
  showSeeMore: boolean;
}) => {
  const dispatch = useAppDispatch();

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const {
    productReviews,
    filter,
    state: commonState,
  } = useAppSelector((state) => state.common);

  const is_logged_in = logged_in_user || access_token;

  return (
    <div className="comments-area pb-0">
      <div className={`d-flex flex-row gap-4`}>
        <div className="col-lg-8 order-0 pb-30">
          {isModal && (
            <div className="shop-product-fillter">
              <div className="sort-by-product-area">
                <div className="sort-by-cover">
                  <CustomSelect
                    name="filterBy"
                    formValue={filter.rating || "all"}
                    options={[
                      { value: "all", label: "All" },
                      { value: "5", label: "5 Star" },
                      { value: "4", label: "4 Star" },
                      { value: "3", label: "3 Star" },
                      { value: "2", label: "2 Star" },
                      { value: "1", label: "1 Star" },
                    ]}
                    inline={{
                      title: "Filter by",
                      icon: "ti-angle-down",
                    }}
                    onChange={(e: any) => {
                      dispatch(
                        setFilters({
                          ...filter,
                          rating: e.target.value.toString(),
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div
            className={`comment-list ${isModal ? "overflow-y-scroll" : ""}`}
            style={{ height: isModal ? "calc(100vh - 220px)" : "auto" }}
          >
            {commonState.status === STATUSES.LOADING &&
            commonState.type === "fetch-reviews" ? (
              <div className="d-flex flex-row justify-content-center">
                <Loader size="lg" />
              </div>
            ) : reviews.review_list && reviews.review_list?.length > 0 ? (
              reviews.review_list?.map((review: IReview, index: number) => (
                <Comment
                  key={index}
                  name={review.customer_details.name?.split(" ")?.[0]}
                  created_at={review.created_at}
                  since={review.customer_details.created_at}
                  rating={review.rating}
                  comment={review.review_comment}
                  contents={review.review_media || []}
                  verified={review.is_verified}
                />
              ))
            ) : null}
            <div className="d-flex flex-row gap-2">
              {!isModal && is_logged_in && (
                <Button
                  className="btn btn-sm"
                  onClick={() =>
                    dispatch(
                      setQuickView({
                        state: true,
                        type: "product-review",
                        data: {
                          product_id: product_id,
                        },
                      })
                    )
                  }
                  loading={false}
                  text={"Write a Review"}
                  type="button"
                />
              )}
              {showSeeMore && !isModal && (
                <Button
                  className="btn btn-sm"
                  onClick={() =>
                    dispatch(
                      setQuickView({
                        state: true,
                        type: "review-list",
                        data: [],
                      })
                    )
                  }
                  loading={false}
                  text={"See More"}
                  type="button"
                />
              )}
            </div>
          </div>
          {isModal && productReviews.total_pages > 1 && (
            <div className="pagination-area mt-5 mb-sm-5 mb-lg-0 mb-0">
              <nav aria-label="Page navigation example">
                <Pagination
                  total_page={reviews.total_pages}
                  serverFilters={false}
                />
              </nav>
            </div>
          )}
        </div>
        <div className="col-lg-4 order-1 pb-30">
          {reviews.average_rating > 0 ? (
            <div className="d-flex align-items-center mb-30 average_rating">
              <Ratings rating={reviews.average_rating} />
              <h6 className="ml-5">{`${to2Decimal(
                reviews.average_rating,
                1
              )} out of 5`}</h6>
            </div>
          ) : null}
          {reviews?.review_statistics?.length > 0 &&
            reviews?.review_statistics?.map((rating, index) => (
              <ProgressBar
                key={index}
                label={`${rating.rating} Star`}
                width={to2Decimal(rating?.percentage || 0)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export { ReviewList };
