"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";

const BannerSlide = ({
  item,
  index,
  widgetIndex,
  category = false,
}: {
  item: any;
  index: number;
  widgetIndex: number;
  category?: boolean;
}) => {
  const DEFAULT_BLUR_DATA_URL =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
  return (
    <div
      className={`single-hero-slider ${
        !category && `rectangle slider-content-center`
      } single-animation-wrap`}
    >
      {item.link ? (
        <Link href={`/${item.link}`}>
          <Image
            className="w-100 h-100"
            src={item.img}
            alt={item?.alt || ""}
            width={item.width || 1600}
            height={item.height || 600}
            blurDataURL={item.blurURL || DEFAULT_BLUR_DATA_URL}
            placeholder="blur"
            loading="eager"
          />
        </Link>
      ) : (
        <Image
          className="w-100 h-100"
          alt={item?.alt || ""}
          src={item.img}
          width={item.width || 1600}
          height={item.height || 600}
          blurDataURL={item.blurURL || DEFAULT_BLUR_DATA_URL}
          placeholder="blur"
          loading="eager"
        />
      )}
    </div>
  );
};

export { BannerSlide };
