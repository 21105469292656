"use client";

import React from "react";
import { ListingWithFilters, ProductHamperDeal } from "../Listing";
import { STATIC_CONSTANTS } from "@/utils";
import { BannersList, Banner } from "../Banners";
import Image from "next/image";

const Festive01 = () => {
  return (
    <>
      {/* <Banner
        src={"/assets/imgs/banner/festive-banner-1.png"}
        alt={""}
        widgetIndex={0}
      /> */}
      <ProductHamperDeal
        data={STATIC_CONSTANTS.PRODUCT_HAMPER_DEAL}
        widgetIndex={0}
      />
      <section className="bg-primary-2 section-padding-12">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Image
                src={"/assets/imgs/banner/festive-banner-2.png"}
                alt={""}
                width={1600}
                height={600}
                className={`w-100 h-100`}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="pt-80 pb-40">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-12"> */}
      <ListingWithFilters
        type="festive-01"
        data={STATIC_CONSTANTS.PRODUCTS}
        title="Best Offers"
        filters={[]}
        extraFilters={false}
      />
      {/* </div>
          </div>
        </div>
      </section> */}
      <BannersList
        banners={STATIC_CONSTANTS.BANNERS}
        direction={"horizontal"}
        widgetIndex={0}
      />
    </>
  );
};

export { Festive01 };
