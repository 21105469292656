"use client";

import {
  constructStructuredData,
  IFAQ,
  TProductDetails,
  IReview,
} from "@/utils";
import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useMemo, useState } from "react";

const StructuredData = ({
  faqs,
  productDetails,
  reviews,
  nonce,
}: {
  faqs?: IFAQ[];
  productDetails?: TProductDetails;
  reviews?: IReview[];
  nonce: string | null;
}) => {
  const paths = usePathname();

  const pathNames = paths.split("/").filter((path) => path);

  const [structuredData, setStructuredData] = useState<any[]>();

  const renderStructuredData = useMemo(() => {
    return structuredData && structuredData?.length > 0
      ? structuredData?.map((data, index) => (
          <Script
            key={`${paths}-${index}`}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(data),
            }}
            nonce={nonce || ""}
          />
        ))
      : null;
  }, [structuredData?.length]);

  useEffect(() => {
    const existingScripts = document.querySelectorAll(
      "script[type='application/ld+json']"
    );

    if (existingScripts?.length > 0) {
      existingScripts.forEach((script) => script.remove());
    }

    setStructuredData(
      constructStructuredData({
        slug: pathNames,
        type: pathNames?.length === 3 ? "Product" : "Static",
        faqs: pathNames.includes("faqs") ? faqs : [],
        productDetails: productDetails,
        reviews: reviews,
      })
    );
  }, []);

  return <>{renderStructuredData}</>;
};

export { StructuredData };
