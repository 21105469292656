"use client";

import { ICustomInputProps } from "@/utils";
import { useField } from "formik";
import React, { useState } from "react";
import { CustomSelect } from "./CustomSelect";

const CustomInput = ({
  name,
  label,
  labelAddOn,
  value,
  type,
  className,
  isRequired,
  titleCase,
  formikProps,
  inputClassName,
  onChange,
  inputRef,
  countryCode = false,
  ...props
}: ICustomInputProps) => {
  const [field, meta] = formikProps ? useField(name) : [null, null];
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueToSet = titleCase
      ? e.target.value.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())
      : e.target.value;
    setInternalValue(valueToSet);
    onChange?.(e);
    if (formikProps) {
      formikProps.setFieldValue(name, valueToSet, true);
    }
  };

  const formValue = formikProps ? field?.value : internalValue;
  const formError = formikProps ? meta?.touched && meta?.error : null;

  return (
    <div className={`form-group ${className}`}>
      <label className="d-flex align-items-center justify-content-between gap-1">
        <div className="d-flex gap-1">
          {label}
          {isRequired ? <span className="text-danger">*</span> : ""}
        </div>
        {labelAddOn}
      </label>
      <div
        className={`input-group ${countryCode ? "d-flex flex-row gap-2" : ""}`}
      >
        {countryCode && (
          <CustomSelect
            options={[]}
            className={"col-4"}
            name="weight"
            onChange={(e: any) => {}}
          />
        )}
        <input
          {...field}
          {...props}
          ref={inputRef}
          type={type}
          pattern={type === "number" ? "[0-9]*" : undefined}
          value={formValue}
          onChange={handleChange}
          onWheel={(e: any) => {
            e.target.blur();
          }}
          className={`py-2 form-control rounded-lg ${
            inputClassName ? inputClassName : ""
          } ${countryCode ? "col-8" : ""}`}
        />
      </div>
      {formError ? (
        <div className="text-danger my-1 text-start">{meta?.error}</div>
      ) : null}
    </div>
  );
};

export { CustomInput };
