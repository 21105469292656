"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import {
  GENERAL_CONSTANTS,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
  TOAST_CONSTANTS,
} from "./constants";
import {
  addProduct,
  addToCart,
  AppDispatch,
  setMaxPageLimitState,
  setMinPageLimitState,
  setPlacedOrderId,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { Bounce, toast, ToastPosition, TypeOptions } from "react-toastify";
import {
  ICartItem,
  IDiscount,
  IPayment,
  IProduct,
  RazorpayOptionsPaymentDetails,
  RazorpayOptionsResponse,
  RequestMethod,
} from "./types";
import moment from "moment";
import crypto from "crypto";
import { calculatePrice, getPaymentModeId } from "./actions";
import { apiRequest } from "./server_actions";

export async function apiRequestClient({
  url,
  method,
  contentType,
  data,
  showToast = true,
  signal,
}: {
  url: string;
  method: RequestMethod;
  contentType: string;
  data?: any;
  showToast?: boolean;
  signal?: AbortSignal | null;
}) {
  let response;

  if (method === "GET") {
    response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": contentType,
      },
      signal: signal,
    });
  } else {
    response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": contentType,
      },
      body: JSON.stringify(data),
      signal: signal,
    });
  }

  let responseData;

  if (response.headers.get("Content-Type")?.includes("application/json")) {
    responseData = await response.json();
  } else {
    responseData = await response.text(); // Fallback to plain text or empty response
  }

  if (!response.ok) {
    if (Object.keys(responseData?.result)?.length > 0) {
      let errorMessages = Object.values(responseData?.result)?.flat();

      showToast &&
        errorMessages &&
        errorMessages?.forEach((message: any) => {
          Toast({
            message: capitalizeFirstLetter(message),
            type: TOAST_CONSTANTS.ERROR,
          });
        });
    } else {
      showToast &&
        Toast({
          message:
            capitalizeFirstLetter(responseData?.message) ||
            responseData ||
            "Something went wrong",
          type: TOAST_CONSTANTS.ERROR,
        });
    }

    if (!showToast) {
      throw (
        JSON.stringify({
          error: {
            message: capitalizeFirstLetter(responseData?.message),
            status: response.status,
          },
        }) ||
        responseData ||
        "Something went wrong"
      );
    } else {
      throw new Error(responseData.message);
    }
  }

  return responseData;
}

export const usePreviousRoute = (): string | null => {
  const pathname = usePathname();
  const prevPathRef = useRef<string | null>(null);

  useEffect(() => {
    prevPathRef.current = pathname;
  }, [pathname]);

  return prevPathRef.current;
};

export const usePagination = () => {
  const searchParams = useSearchParams();
  const { push, replace } = useRouter();
  const pathname = usePathname();

  const [search, setSearch] = useState(searchParams.get("query") || "");
  const currentPage = Number(searchParams.get("page")) || 1;

  const { maxPageLimit, minPageLimit } = useAppSelector(
    (state) => state.common
  );

  useEffect(() => {
    // return () => {
    //   setSearch("");
    // };
  }, []);

  const params = new URLSearchParams(searchParams);
  const dispatch = useAppDispatch();

  const handlePageUpdate = (page_no: number = 1) => {
    params.set("page", page_no.toString());

    push(`?${params.toString()}`, { scroll: false });
  };

  const onNextClick = (page_no: number) => {
    if (page_no > maxPageLimit) {
      dispatch(
        setMaxPageLimitState(maxPageLimit + GENERAL_CONSTANTS.PER_PAGE_LIMIT)
      );
      dispatch(
        setMinPageLimitState(minPageLimit + GENERAL_CONSTANTS.PER_PAGE_LIMIT)
      );
    }
    handlePageUpdate(page_no);
  };

  const onPreviousClick = (page_no: number) => {
    if (page_no % GENERAL_CONSTANTS.PER_PAGE_LIMIT === 0) {
      dispatch(
        setMaxPageLimitState(maxPageLimit - GENERAL_CONSTANTS.PER_PAGE_LIMIT)
      );
      dispatch(
        setMinPageLimitState(minPageLimit - GENERAL_CONSTANTS.PER_PAGE_LIMIT)
      );
    }
    handlePageUpdate(page_no);
  };

  const handleSearch = (search: string) => {
    params.set("page", "1");
    setMaxPageLimitState(GENERAL_CONSTANTS.PER_PAGE_LIMIT);
    setMinPageLimitState(GENERAL_CONSTANTS.PER_PAGE_LIMIT);
    if (search) {
      params.set("query", search);
    } else {
      params.delete("page");
      params.delete("query");
    }

    replace(`${pathname}?${params.toString()}`);
  };

  const handleAdditionalFilter = (
    params_key: string = "",
    params_value: string = ""
  ) => {
    if (params_value) {
      params.set(params_key, params_value);
    } else {
      params.delete(params_key);
    }
    replace(`${pathname}?${params.toString()}`, { scroll: false });
  };

  const handleAllFilterOnce = (value: any) => {
    if (value && Object.keys(value).length > 0) {
      for (let key of Object.keys(value)) {
        if (value[key]) {
          params.set(key, `${value[key]}`);
        } else {
          params.delete(key);
        }
      }
      replace(`${pathname}?${params.toString()}`, { scroll: false });
    } else {
      replace(`${pathname}`, { scroll: false });
    }
  };

  const handleKeyDownClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(search);
    }
  };

  return {
    updatePage: handlePageUpdate,
    updateSearch: handleSearch,
    handleEnterClick: handleKeyDownClick,
    search,
    setSearch,
    currentPage,
    searchParams,
    additionalFilter: handleAdditionalFilter,
    handleAllFilterOnce,
    onNextClick,
    onPreviousClick,
  };
};

export function filterNullElements(arr: any[]): any[] {
  if (
    arr &&
    arr.every((element) => element === null && element === undefined)
  ) {
    return [];
  } else {
    return (
      arr && arr.filter((element) => element !== null && element !== undefined)
    );
  }
}

export const Toast = ({
  message,
  type,
  icon,
  position = toast.POSITION.TOP_RIGHT,
  closeDuration = 3000,
}: {
  message: string;
  type?: TypeOptions;
  icon?: React.ReactNode;
  position?: ToastPosition;
  closeDuration?: number;
}) => {
  toast(message, {
    icon: icon,
    position: position,
    autoClose: closeDuration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: type,
    transition: Bounce,
  });
};

export const to2Decimal = (input: number | string, upto: number = 2) => {
  if (input?.toString()?.includes("-")) {
    return Number(input);
  } else {
    return Number(Number(input).toFixed(upto));
  }
};

export const displayValue = (num: number, upto: number = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: upto,
  });
  return formatter.format(num);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const useClickOutside = ({
  ref,
  callback,
}: {
  ref: React.MutableRefObject<any>;
  callback: () => void;
}) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay = 1000
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout> | null = null;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    const context = this;

    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

export const advancedDebounce = <T extends (...args: any[]) => any>(
  func: T,
  delay = 1000,
  immediate = false
): ((
  this: unknown,
  ...args: Parameters<T>
) => Promise<ReturnType<T> | void>) => {
  let timer: ReturnType<typeof setTimeout> | null = null;
  let resolveFn: ((value: ReturnType<T> | void) => void) | null = null;

  return function (
    this: unknown,
    ...args: Parameters<T>
  ): Promise<ReturnType<T> | void> {
    const context = this;

    if (timer) clearTimeout(timer);

    if (immediate && !timer) {
      const result = func.apply(context, args);
      return Promise.resolve(result);
    }

    return new Promise<ReturnType<T> | void>((resolve) => {
      resolveFn = resolve;

      timer = setTimeout(() => {
        timer = null;

        if (!immediate) {
          const result = func.apply(context, args);
          if (resolveFn) resolveFn(result);
        } else if (resolveFn) {
          resolveFn();
        }
      }, delay);
    });
  };
};

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const isNumber = (value: string) => {
  return !isNaN(Number(value));
};

export const seperateFirstLastName = (name: string) => {
  const names = name.split(" ");
  return {
    first_name: names[0],
    last_name: names.slice(1).join(" "),
  };
};

export const formatDateTime = (date: string) => {
  const parsedTimestamp = moment(date);
  // const modifiedTimestamp = parsedTimestamp.add(5, "hours").add(30, "minutes");
  return parsedTimestamp.format("DD MMM YYYY [at] hh:mm A");
};

export const formatDate = (date: string) => {
  const parsedTimestamp = moment(date);

  return parsedTimestamp.format("Do MMMM YYYY");
};

export function strip_html_tags(str: string) {
  // Check if the input string is null or empty
  if (str === null || str === "") {
    // If so, return false
    return "false";
  } else {
    // If not, convert the input string to a string type
    str = str.toString();
  }
  // Use a regular expression to replace all HTML tags with an empty string
  return str.replace(/<[^>]*>/g, " ");
}

export const handleCart = ({
  product,
  is_logged_in,
  dispatch,
  selected_quantity = 1,
  selected_weight,
  onSuccess,
}: {
  product: IProduct;
  is_logged_in: boolean;
  dispatch: AppDispatch;
  selected_quantity?: number;
  selected_weight: { id: number; value: number; label: string; uom: string }[];
  onSuccess: () => void;
}) => {
  if (is_logged_in) {
    dispatch(
      addToCart({
        product_id: product.id,
        quantity: selected_quantity || 1,
        weight_id: selected_weight?.[0]?.id || 1,
        onSuccess: onSuccess,
      })
    );
  } else {
    dispatch(
      addProduct({
        product: {
          title: product.title,
          id: product.id,
          payable_amount:
            (product.selling_uom === "pcs"
              ? product.price
              : calculatePrice(
                  1000,
                  product.price,
                  selected_weight?.[0]?.value
                )) * (selected_quantity || 1),
          price:
            product.selling_uom === "pcs"
              ? product.price
              : calculatePrice(
                  1000,
                  product.price,
                  selected_weight?.[0]?.value
                ),
          discount_value: 0,
          discount_code: "",
          discount_type: "flat",
          rating: product.rating,
          quantity: selected_quantity || 1,
          oldPrice: product.oldPrice,
          slug: product.slug,
          images: product.images,
          thumbnails: product.thumbnails,
          weights: product.weights,
          weight_id: selected_weight?.[0]?.id || 1,
          weight: selected_weight?.[0]?.value || 1,
          uom: selected_weight?.[0]?.uom || "kg",
        },
        onSuccess: onSuccess,
      })
    );
  }
};

export function toTitleCase(sentence: string) {
  return sentence
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function extractProductNumber(obj: any, type: string = "product") {
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      const matcher = type === "category" ? /category-(\d+)/ : /product-(\d+)/;

      const match = obj[key].match(matcher);

      if (match) {
        return match[1];
      }
    }
  }
  return null;
}

export function shuffle(array: any[]) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function constructTastes(tastes: string[]): string {
  if (!tastes) return "Sweet";

  if (tastes.length > 1) {
    return tastes.join(", ");
  } else if (tastes.length === 1) {
    return tastes[0];
  } else {
    return "Sweet";
  }
}

export const calculateTax = (
  payable_amount: number,
  tax: number,
  cess: number,
  net_payable_amount: number,
  bill_discount: number
) => {
  const new_payable_amount = to2Decimal(
    bill_discount !== undefined
      ? payable_amount -
          to2Decimal(bill_discount * (payable_amount / net_payable_amount))
      : payable_amount
  );

  const cessAmount =
    new_payable_amount - (new_payable_amount * 100) / (100 + cess);

  const gst =
    new_payable_amount -
    cessAmount -
    ((new_payable_amount - cessAmount) * 100) / (100 + tax);

  return {
    gst: to2Decimal(gst),

    cess: to2Decimal(cessAmount),

    bill_discount_per_item: to2Decimal(
      bill_discount !== undefined
        ? to2Decimal(bill_discount * (payable_amount / net_payable_amount))
        : 0
    ),

    new_payable_amount: to2Decimal(new_payable_amount),
  };
};

export const updateValuesForItem = (
  selling_price: number,
  new_quantity: number,
  // tax: number,
  discount: IDiscount,
  weight: number = 1000
) => {
  // const item_amount = to2Decimal(selling_price * new_quantity);
  const item_amount = to2Decimal((selling_price * weight) / 1000);

  const discount_amount =
    discount.discount_type === "percentage"
      ? to2Decimal(item_amount * (discount.discount_value / 100))
      : discount.discount_value;

  const amount_with_discount = to2Decimal(
    (item_amount - discount_amount) * new_quantity
  );

  // const tax_amount = item_amount - (item_amount * 100) / (100 + tax);

  return {
    // tax_amount: to2Decimal(tax_amount),
    discount_amount: to2Decimal(discount_amount),
    total_amount: to2Decimal(selling_price * new_quantity),
    payable_amount: to2Decimal(amount_with_discount),
  };
};

export const calculateAllValues = (cartItems: ICartItem[]) => {
  const total_items = cartItems?.reduce((acc, item) => {
    return acc + to2Decimal(Number(item.quantity));
  }, 0);

  const total_weight = cartItems?.reduce((acc, item) => {
    return acc + (to2Decimal(Number(item.quantity) * item.weight) || 0);
  }, 0);

  const total_tax = to2Decimal(
    cartItems?.reduce((acc: number, item) => {
      return acc + to2Decimal(Number(item.tax_applied));
    }, 0)
  );

  const sub_total = to2Decimal(
    cartItems?.reduce((acc: number, item) => {
      return acc + item.payable_amount;
    }, 0)
  );

  const round_off = to2Decimal(sub_total - Math.round(sub_total));

  const total_amount = sub_total - round_off;

  return {
    total_weight,
    total_items,
    total_tax,
    sub_total,
    round_off,
    total_amount,
  };
};

export const jsonDataToFormData = (data: any): FormData => {
  const formData = new FormData();

  function traverse(obj: any, prefix: string) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = prefix ? `${prefix}[${key}]` : key;
        if (typeof obj[key] === "object" && !(obj[key] instanceof File)) {
          traverse(obj[key], propName);
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
  }

  traverse(data, "");

  return formData;
};

export const base64ToArrayBuffer = (data: string) => {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (let i = 0; i < bLength; i++) {
    const ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const printBill = (response: any): void => {
  const content = base64ToArrayBuffer(response.result.bill);
  const blob = new Blob([content], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);

  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = url;
  document.body.appendChild(iframe);
  iframe?.contentWindow?.print();
};

export const generateRazorpayOptions = (
  razorpayResponse: RazorpayOptionsResponse,
  paymentFailure: boolean,
  router: any,
  dispatch: AppDispatch,
  orderDetails: boolean = false,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return {
    key: STATIC_CONSTANTS.ENV_CONSTANTS.RAZORPAY_ID,
    amount: razorpayResponse.amount,
    currency: STATIC_CONSTANTS.RAZORPAY_CONSTANTS.DEFAULT_CURRENCY,
    name: razorpayResponse.notes.customer_name,
    contact: razorpayResponse.notes.customer_contact_number,
    email: razorpayResponse.notes.customer_email,
    description: "description",
    order_id: razorpayResponse.id,
    handler: async function (response: any) {
      try {
        const data: RazorpayOptionsPaymentDetails = {
          paymentMappingId: razorpayResponse.payment_mapping_id || 0,
          orderCreationId: razorpayResponse.id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await fetch("/api/razorpay/verify/", {
          method: REQUEST_METHOD.POST,
          body: JSON.stringify(data),
          headers: { "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON },
        });

        const res = await result.json();

        if (res.isOk) {
          setLoading(false);
          if (orderDetails) {
            router.refresh();
          } else {
            dispatch(setPlacedOrderId(razorpayResponse?.notes?.order_id));
            router.push("/success");
          }
        }
      } catch (error: any) {
        if (orderDetails) {
          router.refresh();
        } else {
          router.push("/account/orders");
        }
      }
    },
    prefill: {
      name: razorpayResponse.notes.customer_name,
      email: razorpayResponse.notes.customer_email,
      contact: razorpayResponse.notes.customer_contact_number,
    },
    modal: {
      ondismiss: async function () {
        try {
          await onPaymentFailure({
            paymentFailure: paymentFailure,
            razorpay_order_response: {
              notes: {
                order_id: razorpayResponse.notes.order_id,
              },
              id: razorpayResponse.id,
              payment_mapping_id: razorpayResponse.payment_mapping_id,
            },
          });
        } catch (error: any) {
          console.log("====================================");
          console.log("OnModalDismiss error", error);
          console.log("====================================");
        } finally {
          if (orderDetails) {
            router.refresh();
          } else {
            router.push("/account/orders");
          }
        }
      },
    },
  };
};

export const addPaymentMapping = async ({
  razorpay_order_id,
  order_id,
}: {
  razorpay_order_id: string;
  order_id: number;
}) => {
  try {
    const paymentToAdd: IPayment = {
      payment_gateway_order: razorpay_order_id,
      order: order_id,
      payment_gateway: "razorpay",
    };

    const payment_response = await fetch("/api/order/payment/", {
      method: REQUEST_METHOD.POST,
      headers: {
        "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
      },
      body: JSON.stringify(paymentToAdd),
    }).then((res) => res.json());

    return payment_response.result.id;
  } catch (error: any) {
    console.log("====================================");
    console.log("Add Payment Mapping", error);
    console.log("====================================");
  }
};

export const onPaymentFailure = async ({
  paymentFailure = false,
  error_response,
  razorpay_order_response,
  payment_mode,
}: {
  paymentFailure: boolean;
  error_response?: any;
  razorpay_order_response: {
    notes: {
      order_id: number;
    };
    id: string;
    payment_mapping_id: number;
  };
  payment_mode?: string;
}) => {
  let payment_details_to_update: any = {
    order: razorpay_order_response.notes.order_id,
    payment_gateway: "razorpay",
    payment_gateway_order: razorpay_order_response.id,
    payment_status: STATIC_CONSTANTS.RAZORPAY_CONSTANTS.PAYMENT_FAILED_STATUS,
    payment_amount: 0,
  };

  if (error_response) {
    payment_details_to_update = {
      ...payment_details_to_update,
      payment_gateway_id: error_response.error.metadata.payment_id,
    };
  }

  if (payment_mode) {
    payment_details_to_update = {
      ...payment_details_to_update,
      payment_mode: getPaymentModeId(payment_mode),
    };
  }

  if (paymentFailure) {
    try {
      const payment_update_response = await fetch("/api/order/payment/", {
        method: REQUEST_METHOD.POST,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
        body: JSON.stringify(payment_details_to_update),
      }).then((res) => res.json());
    } catch (error: any) {
      console.log("====================================");
      console.log("payment add error", error);
      console.log("====================================");
    }
  } else {
    try {
      const payment_update_response = await apiRequest({
        url: `${STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL}/shop/orders/payment/${razorpay_order_response.payment_mapping_id}/`,
        method: REQUEST_METHOD.PUT,
        additionalHeaders: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
        body: JSON.stringify(payment_details_to_update),
      }).then((res) => res.json());
    } catch (error: any) {
      console.log("====================================");
      console.log("payment edit error", error);
      console.log("====================================");
    }
  }
};

export function containsNumbers(str: string): boolean {
  if (!str) return false;
  return Boolean(str.match(/\d/));
}

export const encryptSymmetric = (key: string, plaintext: string) => {
  const iv = crypto.randomBytes(12).toString("base64");
  const cipher = crypto.createCipheriv(
    "aes-256-gcm",
    Buffer.from(key, "base64"),
    Buffer.from(iv, "base64")
  );
  let ciphertext = cipher.update(plaintext, "utf8", "base64");
  ciphertext += cipher.final("base64");
  const tag = cipher.getAuthTag();

  return { ciphertext, iv, tag };
};

export const decryptSymmetric = (
  key: string,
  ciphertext: string,
  iv: string,
  tag: any
) => {
  const decipher = crypto.createDecipheriv(
    "aes-256-gcm",
    Buffer.from(key, "base64"),
    Buffer.from(iv, "base64")
  );

  decipher.setAuthTag(Buffer.from(tag, "base64"));

  let plaintext = decipher.update(ciphertext, "base64", "utf8");
  plaintext += decipher.final("utf8");

  return plaintext;
};

export const constuctAddress = (address: any) => {
  return {
    name: `${address.first_name} ${address.last_name}`,
    address_line_1: `${address.address_line_1} ${address.address_line_2} `,
    address_line_2: `${address.landmark}`,
    city: address.city.id,
    state: address.state.id,
    country: address.country.id,
    postal_code: Number(address.pincode),
    phone_number: address.contact_number,
  };
};

export function canPlaceOrder(cartItems: ICartItem[]) {
  return !cartItems?.some((item: ICartItem) => !item.weight);
}

export function getLastPathSegment(url: string): string {
  const segments = url.split("/").filter(Boolean);
  return segments[segments.length - 1];
}
