// actions Optimizations

import { notFound } from "next/navigation";
import {
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
} from "./constants";
import { apiRequest } from "./server_actions";
import {
  IDBMenu,
  IDBProduct,
  IFAQ,
  IProductCMSDetails,
  TProductDetails,
  IReview,
  IBannerContent,
} from "./types";

export const validURL = (url: string) => {
  if (url.startsWith("_next/")) {
    return false;
  }

  return true;
};

export const constructSlug = (slugs: string[]) => {
  let finalSlug = "";
  slugs.forEach((slug, index) => {
    if (index === 0) {
      finalSlug = slug;
    } else {
      finalSlug = `${finalSlug}/${slug}`;
    }
  });
  return finalSlug;
};

export async function fetchPageData(
  slug: string[],
  is_logged_in: boolean = false
) {
  if (slug.includes("_next")) {
    return null; // Skip rendering on the server-side
  }

  const endpoint = `${
    STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL
  }/shop/pages/page/${slug.join("/")}`;

  try {
    const response = is_logged_in
      ? await apiRequest({
          url: endpoint,
          method: REQUEST_METHOD.GET,
          additionalHeaders: {
            "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
          },
          tags: ["page-data"],
        })
      : await fetch(endpoint, {
          method: REQUEST_METHOD.GET,
          next: {
            tags: ["page-data"],
          },
          cache: "reload",
        });

    const result = await response.json();
    return result.result;
  } catch (error) {
    console.error("Error fetching page data:", error);
    // Optionally handle error with a user-friendly message or toast
    return notFound();
  }
}

export async function fetchSiteData() {
  try {
    const response = await fetch(
      STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL +
        "/shop/site_management/manage/site_config/",
      {
        method: REQUEST_METHOD.GET,
        cache: "reload",
      }
    ).then((res) => res.json());

    return response.result;
  } catch (error: any) {
    return null;
    throw new Error(JSON.stringify(error));
  }
}

export async function fetchReviews(slug: string, rating: number) {
  const baseURL = STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL;
  const productSlug = slug.split("/").pop();
  const ratingParam = rating ? `&rating=${rating}` : "";
  const endpoint = `${baseURL}/shop/product/manage/review/?query=${productSlug}${ratingParam}&review_status=pending`;

  try {
    const response = await fetch(endpoint, {
      method: REQUEST_METHOD.GET,
      next: {
        tags: ["reviews"],
      },
      cache: "reload",
    });

    const result = await response.json();

    const { review_list, review_statistics, average_rating } = result.result;

    return {
      total_pages: Math.ceil(review_list.count / 10),
      review_list: review_list.results,
      review_statistics,
      average_rating,
    };
  } catch (error) {
    console.error("Error fetching reviews:", error);
    return null;
  }
}

export const constructUOM = (uom: string) => (uom === "gms" ? "g" : ` ${uom}`);

export const formatProductForOrder = (item: IProductCMSDetails) => {
  if (!item) {
    throw new Error("Product details are missing.");
  }

  const {
    id,
    category,
    sub_category,
    product_name,
    product,
    product_weights,
    product_medias,
  } = item;

  const categoryName = category.display_name;
  const subCategoryName = sub_category.display_name;

  const slug = constructSlug([
    category.meta_slug,
    sub_category.meta_slug,
    item.meta_slug,
  ]);

  const weights = product_weights
    .map((weight) => ({
      id: weight.id,
      value: weight.weight,
      label: weight.weight,
      uom: constructUOM(product?.uom?.uom_code),
    }))
    .sort((a, b) => a.value - b.value);

  const formatMedia = (mediaType: string) =>
    product_medias
      .filter((media) => media.media_type === mediaType)
      .map((media) => ({
        alt: media.alt,
        img: media.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority);

  const images = formatMedia("full");
  const thumbnails = formatMedia("thumbnail");

  return {
    id,
    category: categoryName,
    sub_category: subCategoryName,
    slug,
    title: product_name,
    jain_friendly: product.jain_friendly,
    uom: constructUOM(product?.uom?.uom_code),
    weights,
    rating: 5,
    images,
    thumbnails,
  };
};

export const formatProduct = (item: IDBProduct) => {
  if (!item) {
    throw new Error("Something went wrong while fetching product");
  }

  const {
    id,
    category,
    sub_category,
    product,
    product_name,
    description,
    disclaimer,
    product_weights,
    wishlist_added,
    product_price,
    product_medias,
  } = item;

  const categoryName = category.display_name;
  const subCategoryName = sub_category.display_name;

  const slug = constructSlug([
    category.meta_slug,
    sub_category.meta_slug,
    item.meta_slug,
  ]);

  const weights = product_weights
    .map((weight) => ({
      id: weight.id,
      value: weight.weight,
      label: weight.weight,
      uom: constructUOM(product?.uom?.uom_code),
    }))
    .sort((a, b) => a.value - b.value);

  const discountType =
    product_price?.selling_discount_type === 4 ? "percentage" : "flat";

  const price = product_price?.selling_price || 0;
  const oldPrice = product_price?.mrp > price ? product_price?.mrp || 0 : 0;

  const taxRate = (type: number) =>
    Number(
      product?.product_tax?.find((tax) => tax.tax_type === type)?.tax_rate || 0
    );

  const formatMedia = (mediaType: string) =>
    product_medias
      .filter((media) => media.media_type === mediaType)
      .map((media) => ({
        alt: media.alt,
        img: media.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority);

  const images = formatMedia("full");
  const thumbnails = formatMedia("thumbnail");

  return {
    id,
    category: categoryName,
    sub_category: subCategoryName,
    slug,
    product,
    title: product_name,
    jain_friendly: product.jain_friendly,
    tastes: product?.taste_details?.map((taste) => taste.taste.taste_name),
    description,
    disclaimer,
    weights,
    rating: 5,
    wishlist_added,
    batch_id: product_price?.batch,
    price,
    oldPrice,
    discount: {
      type: product_price ? discountType : "",
      value: product_price?.selling_discount || 0,
    },
    product_id: product.id,
    product_code: product.product_code,
    shelf_life: product.expiration_days,
    net_weight: product.net_weight,
    uom: constructUOM(product?.uom?.uom_code),
    selling_uom: product?.selling_uom?.uom_code,
    tax: taxRate(1),
    cess: taxRate(2),
    taxes: product.product_tax,
    images,
    thumbnails,
  };
};

const to2Decimal = (input: number | string) => {
  if (input.toString().includes("-")) {
    return Number(input);
  } else {
    return Number(Number(input).toFixed(2));
  }
};

export const calculatePrice = (
  netWeight: number,
  basePrice: number,
  weight: number
) => {
  return to2Decimal((basePrice * weight) / netWeight);
};

export const formatMenuData = ({ data }: { data: IDBMenu }): any => {
  if (!data || !data.menu_items || data.menu_items.length === 0) return [];

  return data.menu_items
    .map((menu) => ({
      id: menu.id,
      menu_item_title: menu.menu_item_title,
      slug: menu.url,
      is_mega_menu: menu.is_mega_menu,
      mega_menu: menu.is_mega_menu
        ? formatMenuData({ data: menu.mega_menu })
        : null,
      priority: menu.priority,
      children: menu.children
        .map((child) => ({
          id: child.id,
          menu_item_title: child.menu_item_title,
          slug: child.url,
          priority: child.priority,
        }))
        .sort((a, b) => a.priority - b.priority),
    }))
    .sort((a, b) => a.priority - b.priority);
};

export const transformDataToBannerConfig = async (input: any) => {
  const extractValue = (name: string) =>
    input.content.find((item: any) => item.name === name)?.value?.[0]?.value;

  const bannerContent =
    input.content.find((item: any) => item.name === "banner_content")
      ?.inner_form || [];

  const content: IBannerContent[] = await Promise.all(
    bannerContent.map(async (item: any) => {
      const media = item.find((subItem: any) => subItem.name === "media")?.value
        .preview;

      return {
        media: media,
        alt: item.find((subItem: any) => subItem.name === "alt")?.value,
        title: item.find((subItem: any) => subItem.name === "title")?.value,
        description: item.find((subItem: any) => subItem.name === "description")
          ?.value,
        className: "",
        small: false,
        action: {
          link: item.find((subItem: any) => subItem.name === "btnlink")?.value,
          text: item.find((subItem: any) => subItem.name === "btnText")?.value,
        },
      };
    })
  );

  return {
    content,
    type: extractValue("banner_type") as string,
    display_width: extractValue("display_width") as string,
    flow: extractValue("flow") as string,
    alignment: extractValue("alignment") as string,
  };
};

export const transformDataToListingConfig = (input: any) => {
  const extractValue = (name: string) =>
    input.content.find((item: any) => item.name === name)?.value?.[0]?.value;

  const extractContentValue = (name: string) =>
    input.content.find((item: any) => item.name === name)?.value || [];

  const type = extractValue("type");
  const contentType = extractValue("content_type");
  const title = extractContentValue("title");
  const largeCard = extractValue("largeCard") === 1;
  const extraFilters = extractValue("extraFilters") === 1;

  const filters = extractContentValue("filters").map((item: any) => item.label);

  const formatContentItem = (item: IDBProduct | any) => {
    if (contentType === "product") {
      return formatProduct(item);
    }

    const baseConfig = {
      title: item.display_name,
      img: item.icon,
      desc: item.short_summary,
      alt: item.icon_alt,
      hasDesc: true,
    };

    if (contentType === "category") {
      return { ...baseConfig, link: item.meta_slug };
    }

    if (contentType === "sub-category") {
      return {
        ...baseConfig,
        slug: item.meta_slug,
        link: constructSlug([
          item.product_category.meta_slug || "",
          item.meta_slug,
        ]),
      };
    }
  };

  const content = extractContentValue("content").map(formatContentItem);

  const fetchUrl =
    content.length === 0
      ? `/shop/product/search/${
          input.type === "category"
            ? `?category=${input.page_id}`
            : input.type === "sub_category"
            ? `?subcategory=${input.page_id}`
            : ""
        }`
      : "";

  return {
    content,
    type,
    title,
    largeCard,
    extraFilters,
    filters,
    fetchUrl,
    totalPages: 0,
    count: 0,
    taste: [],
  };
};

export const transformDataToMultipleListingConfig = (input: any) => {
  const listContent =
    input.content.find((item: any) => item.name === "list_content")
      ?.inner_form || [];

  const content = listContent.map((item: any) => {
    const title = item.find((subItem: any) => subItem.name === "title")?.value;

    const contentItems =
      item.find((subItem: any) => subItem.name === "content")?.value || [];

    return {
      title,
      content: contentItems.map((product: IDBProduct) =>
        formatProduct(product)
      ),
    };
  });

  return {
    content,
  };
};

export const transformDataToProductDealsConfig = (input: any) => {
  const extractValue = (name: string) =>
    input.content.find((item: any) => item.name === name)?.value;

  const dealContent =
    input.content.find((item: any) => item.name === "deal_content")
      ?.inner_form || [];

  const content = dealContent.map((item: any) => ({
    media: item.find((subItem: any) => subItem.name === "media")?.value.preview,
    title: item.find((subItem: any) => subItem.name === "title")?.value,
    description: item.find((subItem: any) => subItem.name === "description")
      ?.value,
    action: {
      link: item.find((subItem: any) => subItem.name === "btnlink")?.value,
      text: item.find((subItem: any) => subItem.name === "btnText")?.value,
    },
  }));

  return {
    content,
    main_title: extractValue("main_title"),
    main_description: extractValue("main_description"),
  };
};

export const getPaymentModeId = (payment_mode: string) => {
  switch (payment_mode) {
    case "netbanking":
      return 16;
    case "upi":
      return 15;
    case "card":
      return 14;
    case "wallet":
      return 19;
    default:
      return 0;
  }
};

export function isTokenValid(token: any): boolean {
  const currentTime = Math.floor(Date.now() / 1000);

  if (token.exp < currentTime) {
    return false;
  }

  if (token.iat > currentTime) {
    return false;
  }

  return true;
}

export function generateOrganizationStucturedData() {
  const test = {
    "@context": "https://schema.org",
    "@type": "Organization",
    image: "https://www.example.com/example_image.jpg",
    url: "https://www.example.com",
    sameAs: [
      "https://example.net/profile/example1234",
      "https://example.org/example1234",
    ],
    logo: "https://www.example.com/images/logo.png",
    name: "Example Corporation",
    description:
      "The example corporation is well-known for producing high-quality widgets",
    email: "contact@example.com",
    telephone: "+47-99-999-9999",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Rue Improbable 99",
      addressLocality: "Paris",
      addressCountry: "FR",
      addressRegion: "Ile-de-France",
      postalCode: "75001",
    },
    vatID: "FR12345678901",
    iso6523Code: "0199:724500PMK2A2M1SQQ228",
  };

  return {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Avarya Retail Pvt Ltd",
    alternateName: "Avarya",
    url: "https://www.avarya.in",
    logo: "https://avaryaallimages.s3.ap-south-1.amazonaws.com/site-settings/icon/avarya-brand-logo.png",
    sameAs: [
      "https://www.facebook.com/avaryaretail",
      "https://www.instagram.com/avaryaretail",
      "https://www.youtube.com/@avaryaretail",
    ],
  };
}

export function generateWebsiteStructuredData() {
  return {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Avarya Retail Pvt Ltd",
    url: STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL,
    potentialAction: {
      "@type": "SearchAction",
      target: `${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/search?query={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };
}

export function generateLocalBusinessStructureData() {
  return {
    "@context": "https://schema.org",
    "@type": "ConvenienceStore",
    name: "Avarya Retail Pvt Ltd",
    image:
      "https://avaryaallimages.s3.ap-south-1.amazonaws.com/site-settings/icon/avarya-brand-logo.png",
    id: STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL,
    url: STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL,
    telephone: "+919152414320",
    priceRange: "₹₹₹",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Shop No 5 to 9, Avighna 9,",
      addressLocality: "Lalbaug",
      postalCode: "400012",
      addressCountry: "IN",
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "09:00",
      closes: "21:00",
    },
    sameAs: [
      "https://www.facebook.com/avaryaretail",
      "https://www.instagram.com/avaryaretail",
      "https://www.youtube.com/@avaryaretail",
    ],
    menu: "https://menu.avarya.in/avarya_menu/menu_np/common_menu_np.pdf",
  };
}

export function generateBreadcrumbStructuredData(
  type: "Static" | "Product" | "Category" | "Sub Category",
  slug?: string[]
): any {
  const baseUrl = STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL;

  // Convert slug to breadcrumb parts
  const breadcrumbParts = slug?.map((part, index) => {
    const name = part
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return {
      "@type": "ListItem",
      position: index + 2, // Start from 2 since "Home" is at position 1
      name: name,
      item: `${baseUrl}/${slug.slice(0, index + 1).join("/")}`,
    };
  });

  // Add Home breadcrumb at the beginning
  const breadcrumbData = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    name: `${type} Breadcrumb`,
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: baseUrl,
      },
      ...(breadcrumbParts || []),
    ],
  };

  return breadcrumbData;
}

export function extractContent(html: string): string {
  // Replace <br> tags with line breaks
  const content = html.replace(/<br\s*\/?>/gi, "\n");

  // Remove any remaining HTML tags
  const textContent = content.replace(/<\/?[^>]+(>|$)/g, "");

  return textContent.trim();
}

function findBestAndWorstRatings(reviews: IReview[]) {
  if (reviews.length === 0) {
    return {
      bestRating: null,
      worstRating: null,
    };
  }

  let bestRating = reviews[0].rating;
  let worstRating = reviews[0].rating;

  for (const review of reviews) {
    if (review.rating > bestRating) {
      bestRating = review.rating;
    }
    if (review.rating < worstRating) {
      worstRating = review.rating;
    }
  }

  return {
    bestRating,
    worstRating,
  };
}

export function generateFAQStructuredData(faqs: IFAQ[]): object {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    name: `FAQs`,
    mainEntity: faqs.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: extractContent(faq.answer),
      },
    })),
  };
}

export function generateProductStructuredData(
  productDetails: TProductDetails,
  reviews: IReview[]
) {
  const productSchema: any = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: productDetails.title,
    image: productDetails.images.map((item) => item.img),
    description: extractContent(productDetails.description),
    brand: {
      "@type": "Brand",
      name: "Avarya",
    },
    sku: productDetails.product.product_code.toString(),
    offers: {
      "@type": "Offer",
      url: `${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/${productDetails?.slug}`,
      priceCurrency: "INR",
      price: (productDetails.selling_uom === "pcs"
        ? productDetails.price
        : calculatePrice(
            1000,
            productDetails.price,
            productDetails.weights?.[0]?.value
          )
      ).toString(),
      availability: "https://schema.org/InStock",
      itemCondition: "https://schema.org/NewCondition",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: productDetails.rating.toString(),
      bestRating: "5",
      worstRating: "1",
      ratingCount: "580",
      reviewCount: "580",
      // ratingCount: reviews.length.toString(),
      // reviewCount: reviews.length.toString(),
    },
  };

  if (reviews?.length > 0) {
    const reviewSchema = reviews?.map((review) => ({
      "@type": "Review",
      reviewBody: review.review_comment,
      reviewRating: {
        "@type": "Rating",
        bestRating: "5",
        ratingValue: review.rating.toString(),
        worstRating: "1",
      },
      datePublished: review.review_date.split("T")[0],
      author: {
        "@type": "Person",
        name: review.customer_details.name,
      },
    }));

    productSchema["review"] = reviewSchema;
  }

  return productSchema;
}

export const constructStructuredData = ({
  slug,
  type,
  productDetails,
  reviews,
  products,
  faqs = [],
}: {
  slug?: string[];
  type: "Static" | "Product" | "Category" | "Sub Category";
  productDetails?: TProductDetails;
  reviews?: IReview[];
  products?: TProductDetails[];
  faqs?: IFAQ[];
}) => {
  const jsonLd = [
    generateWebsiteStructuredData(),
    generateOrganizationStucturedData(),
    generateLocalBusinessStructureData(),
    ...(slug && slug?.length > 0
      ? [generateBreadcrumbStructuredData(type, slug)]
      : []),
    ...(faqs?.length > 0 ? [generateFAQStructuredData(faqs)] : []),
    ...(productDetails && reviews
      ? [generateProductStructuredData(productDetails, reviews)]
      : []),
    ...((type === "Category" || type === "Sub Category") &&
    products &&
    products?.length > 0
      ? products?.map((item) => generateProductStructuredData(item, []))
      : []),
  ];

  return jsonLd;
};

export function constructTasteOptions(tastes: any[]) {
  return [
    { value: "", label: "Select..." },
    ...(tastes?.length > 0
      ? tastes?.map((item: any) => ({
          value: item.id,
          label: item.taste_name,
        }))
      : []),
  ];
}
