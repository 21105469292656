import { TypeOptions } from "react-toastify";
import {
  BannerItemProps,
  ButtonConstantstype,
  RequestMethod,
  TSize,
} from "./types";

// Request method object
export const REQUEST_METHOD = Object.freeze({
  POST: "POST" as RequestMethod,
  GET: "GET" as RequestMethod,
  PUT: "PUT" as RequestMethod,
  DELETE: "DELETE" as RequestMethod,
});

// Request status object
export const STATUSES = Object.freeze({
  LOADING: "loading" as string,
  IDLE: "idle" as string,
  ERROR: "error" as string,
  TIME_DELAY: "time_delay" as string,
  NO_INTERNET: "no_internet" as string,
});

// Request Content type
export const REQUEST_CONTENT_TYPE = Object.freeze({
  APP_JSON: "application/json",
  FORM_DATA: "multipart/form-data",
});

// FORM CONSTANTS
export const FORM_CONSTANTS = Object.freeze({
  ERROR_PARENT: "div",
  ERROR: "text-danger mb-2",
  EMAIL: "email",
  PASSWORD: "password",
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  TEXTAREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  SAVE: "Save",
  SUBMIT: "Submit",
  DAYS_OF_WEEK: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  DEFAULT_CITY: [
    {
      value: 133024,
      label: "Mumbai",
    },
  ],
  DEFAULT_STATE: [
    {
      value: 4008,
      label: "Maharashtra",
    },
  ],
  DEFAULT_COUNTRY: [
    {
      value: 101,
      label: "India",
    },
  ],
  DEFAULT_PINCODE: 400001,
});

// GENERAL CONSTANTS
export const GENERAL_CONSTANTS = Object.freeze({
  DATE_FORMAT: "YYYY-MM-DD",
  COMPACT_DATE_FORMAT: "DD/MM/YY",
  TIME_FORMAT: "hh:mm",
  STRING: "string",
  EMPTY_STRING: "",
  EMPTY_ARRAY: [],
  CLEAR: "clear",
  ENTER: "Enter",
  BACK: "Back",
  LOADING: "Loading...",
  SEARCH: "Search ...",
  CONFIRM_MODAL_MESSAGE: "Are you sure you want to proceed?",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  DARK: "dark",
  INFO: "info",
  TRUE: true,
  FALSE: false,
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  PERCENTAGE: "percentage",
  PERCENTAGE_SYMBOL: "%",
  FLAT: "flat",
  FLAT_SYMBOL: "₹",
  NA: "N/A",
  YES: "Yes",
  COMPLETED: "completed",
  PENDING: "pending",
  CANCELLLED: "cancelled",
  NO: "No",
  ALL: "all",
  STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  YES_NO_OPTIONS: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],
  LARGE: "lg" as TSize,
  MEDIUM: "md" as TSize,
  SMALL: "sm" as TSize,
  EXTRA_SMALL: "xs" as TSize,
  EXTRA_LARGE: "xl" as TSize,
  EXTRA_EXTRA_LARGE: "xxl" as TSize,
  NAVIGATE: "navigate",
  PROCEED: "Proceed",
  CONFIRM_CHANGES: "Confirm Changes",
  APPLY: "Apply",
  PER_PAGE_LIMIT: 5,
});

//BUTTON CONSTANTS
export const BUTTON_CONSTANTS: ButtonConstantstype = Object.freeze({
  BUTTON: "button",
  SUBMIT: "submit",
  RESET: "reset",
  SAVE: "Save",
});

// STATIC CONSTANTSF
export const STATIC_CONSTANTS = Object.freeze({
  PRODUCTS: [
    {
      title: "Seeds of Change Organic Quinoa, Brown",
      slug: "seeds-of-change-organic-quinoe",
      moreLove: true,
      id: "1",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-1-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-1-1.jpg",
        },
      ],
      condition: "new",
      vendor: "nestFood",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.jpg",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "broccoli",
      sizes: "s",
      stock: 0,
      review: 10,
      rating: 3,

      created: "",
      filterType: "popular",
    },
    {
      title: "All Natural Italian-Style Chicken Meatballs",
      slug: "all-natural-italian-style-chicken-meatballs",
      moreLove: true,
      id: "2",
      price: 78,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-2-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-2-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-2-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-2-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-2-1.jpg",
        },
      ],
      condition: "refurbished",
      vendor: "stouffer",
      color: "blue",
      brand: "armani",
      category: "jeans",
      featured: true,
      trending: false,
      discount: {
        banner: "/assets/imgs/banner/banner-6.png",
        percentage: 4,
        expireDate: null,
        isActive: false,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "broccoli",
      sizes: "s",
      review: 10,

      created: "2021-02-03T06:48:07",
      filterType: "trending",
    },
    {
      title: "Angie’s Boomchickapop Sweet & Salty",
      slug: "angie’s-boomchickapop-sweet-&-salty",
      moreLove: true,
      id: "3",
      price: 35,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-3-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-3-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-3-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-3-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-3-1.jpg",
        },
      ],
      condition: "new",
      vendor: "starKist",
      color: "blue",
      brand: "armani",
      category: "accessories",
      featured: true,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-7.png",
        percentage: 4,
        expireDate: null,
        isActive: false,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "fruit",
      sizes: "s",
      review: 10,
      rating: 5,

      created: "",
      filterType: "popular",
    },
    {
      title: "Foster Farms Takeout Crispy Classic",
      slug: "foster-farms-takeout-crispy-classic",
      moreLove: true,
      id: "4",
      price: 55,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-4-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-4-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-4-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-4-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-4-1.jpg",
        },
      ],
      condition: "used",
      vendor: "NestFood",
      color: "blue",
      brand: "armani",
      category: "trousers",
      featured: true,
      trending: false,
      discount: {
        banner: "/assets/imgs/banner/banner-6.jpg",
        percentage: 4,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "salad",
      sizes: "s",
      review: 10,

      created: "",
      filterType: "trending",
    },
    {
      title: "Green Diamond Almonds Lightly From Oska",
      slug: "green-diamond-almonds-lightly-from-oska-japan",
      moreLove: true,
      id: "5",
      price: 110,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-5-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-5-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-5-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-5-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-5-1.jpg",
        },
      ],
      condition: "refurbished",
      vendor: "NestFood",
      color: "red",
      brand: "NestFood",
      category: "shoe",
      featured: true,
      trending: false,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 4,
        expireDate: null,
        isActive: false,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "fruit",
      sizes: "s",
      review: 10,
      rating: 5,

      created: "",
      filterType: "popular",
    },
    {
      title: "Chobani Complete Vanilla Greek Yogurt",
      slug: "chobani-complete-vanilla-greek-yogurt",
      moreLove: true,
      id: "6",
      price: 150,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-6-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-6-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-6-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-6-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-6-2.jpg",
        },
      ],
      condition: "refurbished",
      vendor: "NestFood",
      color: "green",
      brand: "helix",
      category: "jeans",
      featured: true,
      trending: false,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 4,
        expireDate: null,
        isActive: false,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "salad",
      sizes: "s",
      review: 10,
      rating: 3,
      created: "",
      filterType: "trending",
    },
    {
      title: "Canada Dry Ginger Ale – 2 L Bottle",
      slug: "canada-dry-ginger-ale-–-2-l-bottle",
      moreLove: true,
      id: "7",
      price: 98,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-7-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-7-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-7-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-7-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-7-1.jpg",
        },
      ],
      condition: "new",
      vendor: "stouffer",
      color: "blue",
      brand: "armani",
      category: "trousers",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 4,
        expireDate: null,
        isActive: false,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "fruit",
      sizes: "s",
      review: 10,

      rating: 5,
      created: "",
      filterType: "popular",
    },
    {
      title: "Encore Seafoods Stuffed Alaskan",
      slug: "encore-seafoods-stuffed-alaskan",
      moreLove: true,
      id: "8",
      price: 24,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-8-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-8-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-8-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-8-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-8-1.jpg",
        },
      ],
      condition: "refurbished",
      vendor: "NestFood",
      color: "blue",
      brand: "armani",
      category: "shoe",
      featured: true,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 4,
        expireDate: null,
        isActive: false,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "milk",
      sizes: "s",
      review: 10,
      rating: 1,
      created: "",
      filterType: "trending",
    },
    {
      title: "Gorton’s Beer Battered Fish Fillets",
      slug: "gorton’s-beer-battered-fish-fillets",
      moreLove: true,
      id: "9",
      price: 146,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-9-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-9-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-9-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-9-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-9-1.jpg",
        },
      ],
      condition: "new",
      vendor: "stouffer",
      color: "blue",
      brand: "armani",
      category: "accessories",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 4,
        expireDate: null,
        isActive: false,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "milk",
      sizes: "s",
      review: 10,

      created: "",
      filterType: "popular",
    },
    {
      title: "Haagen-Dazs Caramel Cone Ice Cream",
      slug: "haagen-dazs-caramel-cone-ice-cream",
      moreLove: true,
      id: "10",
      price: 67,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-10-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-10-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-10-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-10-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-10-1.jpg",
        },
      ],
      condition: "used",
      vendor: "Burbe",
      color: "blue",
      brand: "armani",
      category: "jacket",
      featured: true,
      trending: false,
      discount: {
        banner: "/assets/imgs/banner/banner-8.jpg",
        percentage: 4,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "appetizer",
      sizes: "s",
      review: 10,

      created: "",
      filterType: "trending",
    },
    {
      title: "Hamitoki - Seeds of Change Organic Quinoe",
      slug: "seeds-of-change-organic-quinoe",
      moreLove: true,
      id: "11",
      price: 116,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-11-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-11-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-11-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-11-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-11-1.jpg",
        },
      ],
      condition: "refurbished",
      vendor: "NestFood",
      color: "red",
      brand: "NestFood",
      category: "jacket",
      featured: true,
      trending: false,
      discount: {
        banner: "/assets/imgs/banner/deal-2.jpg",
        percentage: 4,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "milk",
      sizes: "s",
      review: 10,

      created: "",
      filterType: "popular",
    },
    {
      title: "All Natural Italian-Style Chicken Meatballs",
      slug: "all-natural-italian-style-chicken-meatballs",
      moreLove: true,
      id: "12",
      price: 73,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-12-2.jpg",
        },
      ],
      totalSell: 1227,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-12-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-1-1.jpg",
        },
      ],
      condition: "refurbished",
      vendor: "adidas",
      color: "green",
      brand: "helix",
      category: "jeans",
      featured: true,
      trending: false,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 4,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "milk",
      sizes: "s",
      review: 10,

      rating: 5,
      created: "",
      filterType: "trending",
    },
    {
      title: "Angie’s Boomchickapop Sweet & Salty",
      slug: "angie’s-boomchickapop-sweet-&-salty",
      moreLove: true,
      id: "13",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-3-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-13-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-3-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-13-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-3-1.jpg",
        },
      ],
      condition: "new",
      vendor: "iSnack",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "appetizer",
      sizes: "s",
      stock: 8,
      review: 10,

      created: "",
      filterType: "popular",
    },
    {
      title: "Foster Farms Takeout Crispy Classic",
      slug: "foster-farms-takeout-crispy-classic",
      moreLove: true,
      id: "14",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-4-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-14-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-4-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-14-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-4-1.jpg",
        },
      ],
      condition: "new",
      vendor: "stouffer",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "appetizer",
      sizes: "s",
      stock: 8,
      review: 10,
      rating: 3,
      created: "",
      filterType: "popular",
    },
    {
      title: "Blue Diamond Almonds Lightly Salted Vegetables",
      slug: "blue-diamond-almonds-lightly",
      moreLove: true,
      id: "15",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-5-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-15-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-5-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-15-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-5-1.jpg",
        },
      ],
      condition: "new",
      vendor: "Harris",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "snack",
      sizes: "s",
      stock: 8,
      review: 10,
      rating: 3,

      created: "",
      filterType: "popular",
    },
    {
      title: "Helen Tokio Chobani Complete Vanilla Greek",
      slug: "helen-tokio-chobani-complete-vanilla-greek",
      moreLove: true,
      id: "16",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-1-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-6-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-3.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-4.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-5.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-6.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-7.jpg",
        },
      ],
      condition: "new",
      vendor: "Costco",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "snack",
      sizes: "s",
      stock: 8,
      review: 10,
      rating: 3,
      created: "",
      filterType: "popular",
    },
    {
      title: "Canada Dry Ginger Ale – 2 L Bottle",
      slug: "canada-dry-ginger-ale-–-2-l-bottle",
      moreLove: true,
      id: "17",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-1-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-6-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-3.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-4.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-5.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-6.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-7.jpg",
        },
      ],
      condition: "new",
      vendor: "Costco",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "snack",
      sizes: "s",
      stock: 8,
      review: 10,
      rating: 3,

      created: "",
      filterType: "popular",
    },
    {
      title: "Encore Seafoods Stuffed Alaskan",
      slug: "encore-seafoods-stuffed-alaskan",
      moreLove: true,
      id: "18",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-1-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-6-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-3.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-4.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-5.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-6.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-7.jpg",
        },
      ],
      condition: "new",
      vendor: "stouffer",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "snack",
      sizes: "s",
      stock: 8,
      review: 10,
      rating: 3,

      created: "",
      filterType: "popular",
    },
    {
      title: "Gorton’s Beer Battered Fish Fillets",
      slug: "gorton’s-beer-battered-fish-fillets",
      moreLove: true,
      id: "19",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-1-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-6-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-3.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-4.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-5.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-6.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-7.jpg",
        },
      ],
      condition: "new",
      vendor: "stouffer",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "snack",
      sizes: "s",
      stock: 8,
      review: 10,
      rating: 3,
      created: "",
      filterType: "popular",
    },
    {
      title: "Haagen-Dazs Caramel Cone Ice Cream",
      slug: "haagen-dazs-caramel-cone-ice-cream",
      moreLove: true,
      id: "20",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-1-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-6-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-3.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-4.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-5.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-6.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-7.jpg",
        },
      ],
      condition: "new",
      vendor: "stouffer",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "snack",
      sizes: "s",
      stock: 8,
      review: 10,
      rating: 3,

      created: "",
      filterType: "popular",
    },
    {
      title: "Simply Dried Mango - 50 Gram - 1 package",
      slug: "simply-dried-mango",
      moreLove: true,
      id: "20",
      price: 238.85,
      oldPrice: 245.8,
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam rem officia, corrupti reiciendis minima nisi modi, quasi, odio minus dolore impedit fuga eum eligendi? Officia doloremque facere quia. Voluptatum, accusantium!",
      images: [
        {
          img: "/assets/imgs/shop/product-1-1.jpg",
        },
        {
          img: "/assets/imgs/shop/product-1-2.jpg",
        },
      ],
      totalSell: 27,
      gallery: [
        {
          thumb: "/assets/imgs/shop/product-6-1.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-2.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-3.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-4.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-5.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-6.jpg",
        },
        {
          thumb: "/assets/imgs/shop/product-16-7.jpg",
        },
      ],
      condition: "new",
      vendor: "stouffer",
      color: "blue",
      brand: "armani",
      category: "clothing",
      featured: false,
      trending: true,
      discount: {
        banner: "/assets/imgs/banner/banner-5.png",
        percentage: 14,
        expireDate: null,
        isActive: true,
      },
      variations: [
        "red",
        "yellow",
        "white",
        "orange",
        "cyan",
        "green",
        "purple",
      ],
      weight: 150,
      tags: "snack",
      sizes: "s",
      stock: 8,
      review: 10,
      rating: 3,
      created: "",
      filterType: "popular",
    },
  ],
  WEIGHTS: [
    { value: 250, label: "250", uom: "gm" },
    { value: 500, label: "500", uom: "gm" },
    { value: 1, label: "1", uom: "kg" },
  ],
  QUANTITIES: [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ],
  TASTES: [
    { value: "", label: "Select..." },
    { value: 10, label: "Natural" },
    { value: 11, label: "Sweet & Tangy" },
    { value: 12, label: "Salty" },
    { value: 13, label: "Sweet & Spicy" },
    { value: 14, label: "Salty & Spicy" },
    { value: 15, label: "Salty & Tangy" },
    { value: 16, label: "Sweet" },
    { value: 17, label: "Sweet & Salty" },
    { value: 18, label: "Tangy" },
    { value: 19, label: "Less Spicy" },
    { value: 20, label: "Medium Spicy" },
  ],
  BANNERS: [
    {
      img: "/assets/imgs/banner/banner-1.png",
      title: " Everyday Fresh & <br /> Clean with Our <br /> Products",
      btnText: "Shop Now",
      link: "/products",
    },
    {
      img: "/assets/imgs/banner/banner-2.png",
      title: " Make your Breakfast <br /> Healthy and Easy",
      btnText: "Shop Now",
      link: "/products",
    },
    {
      img: "/assets/imgs/banner/banner-3.png",
      title: "The best Organic <br /> Products Online",
      btnText: "Shop Now",
      link: "/products",
    },
  ] as BannerItemProps[],
  FESTIVE_BANNERS: [
    {
      img: "/assets/imgs/banner/festive-banner-7_cleanup.png",
      title: "10% Off Your <br/> Next Purchase",
      btnText: "Shop Now",
      link: "/products",
      className: "text-wrap",
      small: true,
    },
    {
      img: "/assets/imgs/banner/festive-banner-8_cleanup.png",
      title:
        "Free Shipping <br/> <small>Free shipping on domestic orders</small>",
      btnText: "Shop Now",
      link: "/products",
      className: "text-white text-wrap",
      small: true,
    },
  ] as BannerItemProps[],
  CATEGORIES: [
    {
      id: 1,
      title: "Accompaniments",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-1.svg",
      link: "/accompaniments",
    },
    {
      id: 2,
      title: "Bakery",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-2.svg",
      link: "/bakery",
    },
    {
      id: 3,
      title: "Chikki",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-3.svg",
      link: "/chikki",
    },
    {
      id: 4,
      title: "Dryfruits",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-4.svg",
      link: "/dryfruits",
    },
    {
      id: 5,
      title: "Fresh Farsan",
      desc: "Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-5.svg",
      link: "/fresh-farsan",
    },
    {
      id: 6,
      title: "Grocery",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-6.svg",
      link: "/grocery",
    },
    {
      id: 7,
      title: "Health Food",
      desc: "The playful bhel can never be separated from the Indian snack time menu",
      img: "/assets/imgs/theme/icons/category-7.svg",
      link: "/health-food",
    },
    {
      id: 8,
      title: "Khakra",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-8.svg",
      link: "/khakra",
    },
    {
      id: 9,
      title: "Mithai",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel",
      img: "/assets/imgs/theme/icons/category-9.svg",
      link: "/mithai",
    },
    {
      id: 10,
      title: "Mukhwas",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-10.svg",
      link: "/mukhwas",
    },
    {
      id: 11,
      title: "Namkeen",
      desc: "Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-11.svg",
      link: "/namkeen",
    },
    {
      id: 12,
      title: "Travel",
      desc: "The playful bhel can never be separated from the Indian snack time menu. Avarya brings you the most scrumptious bhel – spicy, salty, tangy, sweet, sour and more – you'll ever taste.",
      img: "/assets/imgs/theme/icons/category-1.svg",
      link: "/travel",
    },
  ],
  HOME_CAROUSEL: [
    {
      img: "/assets/imgs/banner/promo-banner-1.jpg",
      title: "Don’t miss <br/> amazing deals",
      description: "Sign up for the daily newsletter",
      className: "text-white text-wrap",
    },
    {
      img: "/assets/imgs/slider/slider-6.png",
      title: "Fresh Vegetables Big discount",
      description: "Save up to 50% off on your first order",
    },
  ],
  CATEGORY_CAROUSEL: [
    {
      img: "/assets/imgs/slider/slider-3.png",
      title: "Pure Coffee <br /> Big discount",
      description: "Save up to 50% off on your first order",
    },
    {
      img: "/assets/imgs/slider/slider-4.png",
      title: "Snacks box <br /> daily save",
      description: "Sign up for the daily newsletter",
    },
  ],
  PRODUCT_HAMPER_DEAL: {
    img: "/assets/imgs/banner/festive-img-1.jpg",
    title: "Gift Hamper with <br /> Salty Treat",
    description: "@ ₹699 Only!",
    price: 699,
    btnText: "Shop Now",
    main_title: "Namkeen",
    main_description:
      "Salty snacks are always found just around the corner, all over the country. You can order yours from Avarya. Namkeens ranging from bhel, chivda, ghatiya of various styles and specialties are found here.",
  },
  PRODUCT_MULTIPLE_HAMPER_DEAL: {
    data: [
      {
        img: "/assets/imgs/banner/festive-img-2.jpg",
        title: "Gift Hamper with <br /> Sweets",
        description: "@ ₹1299 Only!",
        btnText: "Shop Now",
        link: "/product",
      },
      {
        img: "/assets/imgs/banner/festive-img-3.jpg",
        btnText: "Shop Now",
        link: "/product",
        title: "Gift Hamper with <br /> Sweets",
        description: "@ ₹1299 Only!",
      },
      {
        img: "/assets/imgs/banner/festive-img-2.jpg",
        title: "Gift Hamper with <br /> Sweets",
        btnText: "Shop Now",
        link: "/product",
        description: "@ ₹1299 Only!",
      },
    ],
    main_title: "We believe in pure and Organic Quality",
    main_sub_title: "Hurry Up!",
    main_description:
      "Avarya Fine Foods is a manufacturer and retailer of a range of delectable mithais, namkeens and dry fruits. A premium quality food store, Avarya was established in an endeavour to reviving the traditional flavours of India by bringing authentic sweets and snacks, along with exotic dry fruits to urban consumers. We also offer a range of gifting solutions to corporate organizations in customizable packages for meeting their requirements.",
  },
  REVIEWS: [
    {
      name: "Jacky Chan",
      since: "Since 2012",
      rating: 5,
      comment: "Thank you very fast shipping from Poland only 3days.",
    },
    {
      name: "Ana Rosie",
      since: "Since 2008",
      rating: 5,
      comment: "Great low price and works well.",
    },
    {
      name: "Steven Keny",
      since: "Since 2010",
      rating: 5,
      comment:
        "Authentic and Beautiful, Love these way more than ever expected They are Great earphones",
      contents: [
        { url: "/assets/imgs/shop/product-1-1.jpg", type: "image" },
        { url: "/assets/imgs/shop/product-1-1.jpg", type: "image" },
        { url: "/assets/imgs/shop/product-1-1.jpg", type: "image" },
        { url: "https://www.w3schools.com/html/mov_bbb.mp4", type: "video" },
      ],
    },
  ],
  ADDRESS: [
    {
      id: 83274,
      name: "Avinash Nera",
      address: "3522 Interstate, 75 Business Spur, Sault Ste. Marie",
      city: "Mumbai",
      state: "Maharashtra",
      pincode: "400022",
      phone: "99999 99999",
      default: true,
    },
    {
      id: 83275,
      name: "Avinash Nera",
      address:
        "3522 Interstate, 75 Business Spur, Sault Ste. Marie, 75 Business Spur, Sault Ste. Marie",
      city: "Mumbai",
      state: "Maharashtra",
      pincode: "400022",
      phone: "99999 99999",
      default: false,
    },
    {
      id: 83276,
      name: "Avinash Nera",
      address: "3522 Interstate, 75 Business Spur, Sault Ste. Marie",
      city: "Mumbai",
      state: "Maharashtra",
      pincode: "400022",
      phone: "99999 99999",
      default: false,
    },
    {
      id: 83277,
      name: "Avinash Nera",
      address: "3522 Interstate, 75 Business Spur, Sault Ste. Marie",
      city: "Mumbai",
      state: "Maharashtra",
      pincode: "400022",
      phone: "99999 99999",
      default: false,
    },
    {
      id: 83278,
      name: "Avinash Nera",
      address: "3522 Interstate, 75 Business Spur, Sault Ste. Marie",
      city: "Mumbai",
      state: "Maharashtra",
      pincode: "400022",
      phone: "99999 99999",
      default: false,
    },
    {
      id: 83279,
      name: "Avinash Nera",
      address: "3522 Interstate, 75 Business Spur, Sault Ste. Marie",
      city: "Mumbai",
      state: "Maharashtra",
      pincode: "400022",
      phone: "99999 99999",
      default: false,
    },
  ],
  VIDEO_BANNER: {
    subTitle: "Hurry!",
    title: "Deal Title Goes Here!",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    videoUrl: "https://www.youtube.com/embed/PhLB62qkT5Y",
  },
  STORES: [
    {
      name: "Avary - Chembur(East)",
      address:
        "Shop No. 1, Ganesh Bhuvan, DK Sandu Marg, opp. ICICI Bank, Chembur, Mumbai, Maharashtra 400071",
      phone: "(123) 456-7890",
      whatsapp: "(123) 456-7890",
      email: "contact@avarya.com",
      mapLink: "#",
    },
    {
      name: "Avary - Goregaon",
      address:
        "Shop No. 1, Ganesh Bhuvan, DK Sandu Marg, opp. ICICI Bank, Chembur, Mumbai, Maharashtra 400071",
      phone: "(123) 456-7890",
      whatsapp: "(123) 456-7890",
      email: "contact@avarya.com",
      mapLink: "#",
    },
    {
      name: "Avary - Worli",
      address:
        "Shop No. 1, Ganesh Bhuvan, DK Sandu Marg, opp. ICICI Bank, Chembur, Mumbai, Maharashtra 400071",
      phone: "(123) 456-7890",
      whatsapp: "(123) 456-7890",
      email: "contact@avarya.com",
      mapLink: "#",
    },
    {
      name: "Avary - Ghatkopar",
      address:
        "Shop No. 1, Ganesh Bhuvan, DK Sandu Marg, opp. ICICI Bank, Chembur, Mumbai, Maharashtra 400071",
      phone: "(123) 456-7890",
      whatsapp: "(123) 456-7890",
      email: "contact@avarya.com",
      mapLink: "#",
    },
  ],
  TESTIMONIALS: [
    {
      text: "At Avarya, we understand the needs of the Indian consumers. We use ethnic recipes and a combination of traditional and modern methods to bring distinct flavours and tastes in mithais and farsans that linger for a long time.",
      name: "H. Merinda",
      position: "CEO & Co-Founder",
      image: "/assets/imgs/blog/author-1.png",
      quotesImage: "/assets/imgs/theme/icons/icon-quote.png",
      socialLinks: {
        facebook: "#",
        twitter: "#",
        instagram: "#",
        youtube: "#",
      },
    },
    {
      text: "At Avarya, we understand the needs of the Indian consumers. We use ethnic recipes and a combination of traditional and modern methods to bring distinct flavours and tastes in mithais and farsans that linger for a long time.",
      name: "H. Merinda",
      position: "CEO & Co-Founder",
      image: "/assets/imgs/blog/author-1.png",
      quotesImage: "/assets/imgs/theme/icons/icon-quote.png",
      socialLinks: {
        facebook: "#",
        twitter: "#",
        instagram: "#",
        youtube: "#",
      },
    },
    {
      text: "At Avarya, we understand the needs of the Indian consumers. We use ethnic recipes and a combination of traditional and modern methods to bring distinct flavours and tastes in mithais and farsans that linger for a long time.",
      name: "H. Merinda",
      position: "CEO & Co-Founder",
      image: "/assets/imgs/blog/author-1.png",
      quotesImage: "/assets/imgs/theme/icons/icon-quote.png",
      socialLinks: {
        facebook: "#",
        twitter: "#",
        instagram: "#",
        youtube: "#",
      },
    },
  ],
  FAQ: [
    {
      question: "What is the return policy?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      question: "Do you ship oversees and to P.O. boxes?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      question: "Do you have customer service?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      question: "What is the return policy?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      question: "Do you ship oversees and to P.O. boxes?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      question: "Do you have customer service?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      question: "How can I track my order?",
      answer:
        "For Mumbai, your order will be delivered on your chosen date between 10 am to 8 pm. For outside Mumbai, we dispatch all orders as soon as possible after which our shipping partners take between 3-5 days for metro cities.",
    },
    {
      question: "What is the minimum order amount?",
      answer:
        "There is no minimum cart value to order, we happily fulfill all your cravings! However, minimal shipping charges apply automatically for express orders within Mumbai and All India orders.",
    },
    {
      question: "How can I cancel my order?",
      answer:
        "You can cancel your order by calling us on +91 123 456 7890 or by sending an email to",
    },
  ],
  FESTIVE_CALENDAR: [
    { date: "21-Jun-2019", day: "Monday", festival: "Tulsidas Jayanti" },
    {
      date: "6-Jun-2019",
      day: "Thursday",
      festival: "Maharana Pratap Jayanti",
    },
    { date: "15-Aug-2019", day: "Wednesday", festival: "Independence Day" },
    { date: "6-Nov-2019", day: "Thursday", festival: "Janmashtami *Smarta" },
  ],
  SITEMAP: [
    { title: "Home", subItems: [] },
    { title: "About", subItems: [] },
    {
      title: "Festive Offers",
      subItems: [
        {
          title: "Festive 01",
        },
        {
          title: "Festive 02",
        },
        {
          title: "Festive 03",
        },
      ],
    },
    {
      title: "Shop",
      subItems: [
        {
          title: "Category Listing",
          subItems: [
            {
              title: "Accompaniments",
              subItems: [
                {
                  title: "Pickles",
                },
                {
                  title: "Masala",
                },
                {
                  title: "Khichiya",
                },
                {
                  title: "Papad",
                },
                {
                  title: "Fryums",
                },
                {
                  title: "Ghee",
                },
                {
                  title: "Dips",
                },
                {
                  title: "Chutney",
                },
              ],
            },
            {
              title: "Bakery",
              subItems: [
                {
                  title: "Khari",
                },
                {
                  title: "Toast",
                },
                {
                  title: "Butter",
                },
                {
                  title: "Cake",
                },
                {
                  title: "Cookie",
                },
                {
                  title: "Sticks & Lavash",
                },
                {
                  title: "Bread",
                },
              ],
            },
            {
              title: "Chikki",
              subItems: [
                {
                  title: "Slab",
                },
                {
                  title: "Ladoo",
                },
                {
                  title: "One Bite",
                },
                {
                  title: "Thin",
                },
                {
                  title: "Gajak",
                },
              ],
            },
            {
              title: "Dry Fruits",
              subItems: [
                {
                  title: "Almond",
                },
                {
                  title: "Cashew",
                },
                {
                  title: "Pista",
                },
                {
                  title: "Kismis",
                },
                {
                  title: "Anjeer",
                },
                {
                  title: "Walnut",
                },
                {
                  title: "Jaraloo",
                },
                {
                  title: "Exotic",
                },
                {
                  title: "Date",
                },
              ],
            },
            {
              title: "Fresh Farsan",
              subItems: [
                {
                  title: "Dhokla",
                },
                {
                  title: "Fried",
                },
                {
                  title: "Frozen",
                },
                {
                  title: "Ready to Eat",
                },
                {
                  title: "Snackbox",
                },
              ],
            },
            {
              title: "Grocery",
              subItems: [
                {
                  title: "Flour",
                },
                {
                  title: "Pulses",
                },
                {
                  title: "Spices",
                },
                {
                  title: "Vegetables",
                },
                {
                  title: "Fruits",
                },
              ],
            },
            {
              title: "Health Food",
              subItems: [
                {
                  title: "Sugarless Mithai",
                },
                {
                  title: "Diet Namkeen",
                },
                {
                  title: "Glutenfree Khakra",
                },
                {
                  title: "Oilfree Khakra",
                },
                {
                  title: "Seeds",
                },
                {
                  title: "Green Tea",
                },
                {
                  title: "Sugarfree Chocolate",
                },
              ],
            },
            {
              title: "Khakra",
              subItems: [
                {
                  title: "Plain",
                },
                {
                  title: "Flavour",
                },
                {
                  title: "Cow Ghee",
                },
                {
                  title: "Dahi",
                },
                {
                  title: "Coin",
                },
                {
                  title: "Bhakri",
                },
                {
                  title: "Mini",
                },
                {
                  title: "Dosa",
                },
              ],
            },
            {
              title: "Mithai",
              subItems: [
                {
                  title: "Milk",
                },
                {
                  title: "Dryfruit",
                },
                {
                  title: "Bengali",
                },
                {
                  title: "Desi",
                },
                {
                  title: "Chocolate",
                },
                {
                  title: "Shrikhand",
                },
              ],
            },
            {
              title: "Mukhwas",
              subItems: [
                {
                  title: "Sweet",
                },
                {
                  title: "Salty",
                },
                {
                  title: "Churan",
                },
                {
                  title: "Paan",
                },
                {
                  title: "Candy",
                },
              ],
            },
            {
              title: "Namkeen",
              subItems: [
                {
                  title: "Wafer",
                },
                {
                  title: "Bhel",
                },
                {
                  title: "Chivda",
                },
                {
                  title: "Chakli",
                },
                {
                  title: "Farsan",
                },
                {
                  title: "Mathri",
                },
                {
                  title: "Nuts",
                },
                {
                  title: "Sev",
                },
                {
                  title: "Gathiya",
                },
              ],
            },
            {
              title: "Travel",
              subItems: [
                {
                  title: "Dehydrated",
                },
              ],
            },
          ],
        },
        { title: "Product Listing" },
        { title: "Wishlist" },
        { title: "Cart" },
        { title: "Checkout" },
      ],
    },
    { title: "Our Stores", subItems: [] },
    { title: "Testimonials", subItems: [] },
    { title: "Contact Us", subItems: [] },
    { title: "FAQs", subItems: [] },
    { title: "Secure Shopping", subItems: [] },
    { title: "Festive Calendar", subItems: [] },
    { title: "sitemap", subItems: [] },
    { title: "Terms & conditions", subItems: [] },
    { title: "Shipping Policy", subItems: [] },
    { title: "Privacy Policy", subItems: [] },
    { title: "Disclaimer", subItems: [] },
  ],
  PAYMENT_MODES: [
    {
      id: 13,
      payment_mode: "Cash",
      is_active: 1,
      created_at: "2024-01-22T11:53:25.811808",
      updated_at: "2024-01-22T11:53:25.811808",
    },
    {
      id: 14,
      payment_mode: "Card",
      is_active: 1,
      created_at: "2024-01-22T11:53:25.811808",
      updated_at: "2024-01-22T11:53:25.811808",
    },
    {
      id: 15,
      payment_mode: "UPI",
      is_active: 1,
      created_at: "2024-01-22T11:53:25.811808",
      updated_at: "2024-01-22T11:53:25.811808",
    },
    {
      id: 16,
      payment_mode: "Net Banking",
      is_active: 1,
      created_at: "2024-01-22T11:53:25.811808",
      updated_at: "2024-01-22T11:53:25.811808",
    },
    {
      id: 17,
      payment_mode: "Cheque",
      is_active: 1,
      created_at: "2024-01-22T11:53:25.811808",
      updated_at: "2024-01-22T11:53:25.811808",
    },
    {
      id: 18,
      payment_mode: "Credit Note",
      is_active: 1,
      created_at: "2024-01-22T11:53:25.811808",
      updated_at: "2024-01-22T11:53:25.811808",
    },
    {
      id: 19,
      payment_mode: "Wallet",
      is_active: 0,
      created_at: "2024-05-30T13:17:45.995250",
      updated_at: "2024-05-30T13:20:16.252818",
    },
  ],
  LOCAL_PAGES: [
    "contact-us",
    // "festive-offers-1",
    // "festive-offers-2",
    // "festive-offers-3",
    "sitemap",
    "testimonials",
    "faqs",
    "our-stores",
    // "secure-shopping",
    // "festival-calendar",
    // "terms",
    // "shipping-policy",
    // "privacy-policy",
    // "disclaimer",
  ],
  LISTING_EXCLUDE: ["category", "sub_category", "wishlist"],
  DISABLED_BREADCRUMB: ["signin", "register"],
  ENV_CONSTANTS: {
    SECRET: process.env.NEXT_PUBLIC_SECRET || "avarya-secret",
    SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    // SERVER_URL: process.env.NEXT_PUBLIC_LOCAL_SERVER_URL,
    SERVER_URL: process.env.NEXT_PUBLIC_SERVER_URL,
    RAZORPAY_ID: process.env.NEXT_PUBLIC_RAZORPAY_ID || "",
    RAZORPAY_KEY: process.env.NEXT_PUBLIC_RAZORPAY_KEY,
  },
  STORE_ID: 4,
  TOKEN_CONSTANTS: {
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    CUSTOMER_DETAILS: "customer_details",
  },
  RAZORPAY_CONSTANTS: {
    CHECKOUT_URL: "https://checkout.razorpay.com/v1/checkout.js",
    DEFAULT_CURRENCY: "INR",
    PAYMENT_FAILED_STATUS: "failed",
    PAYMENT_SUCCESS_STATUS: "success",
  },
  FOOTER_CONSTANTS: {
    CONTACT_NUMBER: "(+91) - 9152414230",
    CONTACT_LINK: `https://api.whatsapp.com/send?phone=919152414230`,
    MAIL: "info@avarya.in",
    MAIL_LINK: "mailto:info@avarya.in",
    ADDRESS: "Shop No 5 to 9, Avighna 9, Lalbaug, Mumbai - 400012",
    ADDRESS_LINK:
      "https://www.google.com/search?sca_esv=1671dfeb145c5278&tbs=lf:1,lf_ui:10&tbm=lcl&sxsrf=ADLYWIL9Vh6CTPvbJXOf-rpUhsFaRUS2tg:1722240120666&q=Avarya&rflfq=1&num=10&sa=X&ved=2ahUKEwi-xfC05MuHAxXn4zgGHdhcAcAQjGp6BAgnEAE&biw=1536&bih=745&dpr=1#rlfi=hd:;si:;mv:[[19.206521499999997,73.0439797],[18.9330049,72.7829876]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10",
  },
});

export const API_ROUTES = Object.freeze({
  LOGIN: "/account/signin",
  CHECKOUT: "/checkout",
  FB: "/fb/avaryaretail",
  INSTA: "/insta/avaryaretail",
  YT: "https://www.youtube.com/@avaryaretail",
});

// TOAST CONSTANTS
export const TOAST_CONSTANTS = Object.freeze({
  INFO: "info" as TypeOptions,
  SUCCESS: "success" as TypeOptions,
  ERROR: "error" as TypeOptions,
  WARNING: "warning" as TypeOptions,
  DEFAULT: "default" as TypeOptions,
});

// WIDGET CONSTANTS
export const WIDGET_CONSTANTS = Object.freeze({
  BANNER: "banner",
  LISTING: "item-listing",
  MULTIPLE_LISTING: "column-listing",
  DEALS: "promotional-section",
  STATIC: "rich-text-editor",
});

// LOGIN CONSTANTS
export const LOGIN_CONSTANTS = Object.freeze({
  CONTACT_NUMBER: {
    name: "contact_number",
    otp: "mobile_otp",
  },
  EMAIL: {
    name: "email",
    placeholder: "Enter your email* (For order updates)",
    otp: "email_otp",
  },
  EMAIL_OR_CONTACT: {
    name: "email_or_phone",
    placeholder: "Enter email or contact number*",
  },
  OTP: {
    name: "otp",
    placeholder: "Enter OTP*",
  },
  REQUEST_OTP: "Request OTP",
  RESEND_OTP: "Resend code",
});

// REGISTER CONSTANTS
export const REGISTER_CONSTANTS = Object.freeze({
  COUNTRY_CODE: {
    name: "country_code",
  },
  CONTACT_NUMBER: {
    name: "contact_number",
    placeholder: "Enter contact number*",
  },
  NAME: {
    name: "name",
    placeholder: "Enter name*",
  },
  EMAIL: {
    name: "email",
    placeholder: "Enter email*",
  },
});

// PROFILE CONSTANTS
export const PROFILE_CONSTANTs = Object.freeze({
  FIRST_NAME: {
    name: "first_name",
    label: "First Name",
    placeholder: "Enter First Name",
  },
  LAST_NAME: {
    name: "last_name",
    label: "Last Name",
    placeholder: "Enter Last Name",
  },
  EMAIL: {
    name: "email",
    label: "Email Address",
    placeholder: "Enter Email",
  },
  CONTACT_NUMBER: {
    placeholder: "Enter Mobile Number",
    label: "Mobile Number",
    name: "contact_number",
  },
});

// CART CONSTANTS
export const CART_CONSTANTS = Object.freeze({
  COLS: [
    {
      col: "Product",
      className: "custome-checkbox start",
      colSpan: 2,
    },
    {
      col: "Weight",
      className: "start",
    },
    {
      col: "Price",
      className: "start",
    },
    {
      col: "Qty",
      className: "start",
    },
    {
      col: "Amount",
      className: "start",
    },
  ],
});
