"use client";

import { useAppSelector } from "@/store";
import React from "react";
import { CartOffCanvas } from "./Cart";
import { OffCanvas } from "../core-components";

const GlobalOffCanvas = ({
  tokens,
}: {
  tokens: {
    access_token: string;
    refresh_token: string;
  };
}) => {
  const {
    offCanvas: { component },
  } = useAppSelector((state) => state.common);

  const renderOffCanvas = () => {
    switch (component) {
      case "cart":
        return <CartOffCanvas tokens={tokens} />;

      default:
        return null;
    }
  };

  return <OffCanvas type={component || ""}>{renderOffCanvas()}</OffCanvas>;
};

export { GlobalOffCanvas };
