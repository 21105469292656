"use client";

import React, { useState } from "react";
import { Button } from "./Button";
import { Toast, TOAST_CONSTANTS } from "@/utils";
import { uploadImage, useAppDispatch } from "@/store";

const FileUpload = ({
  //   name,
  //   label,
  //   value,
  //   multiple = true,
  //   editImage,
  name,
  value,
  onChange,
  setFieldValue,
  deleteImage,
  ...props
}: {
  name: string;
  label?: string;
  value?: any[];
  multiple?: boolean;
  onChange?: (files: any) => void;
  editImage?: () => void;
  deleteImage?: (value: any) => void;
  setFieldValue?: any;
}) => {
  const validExtensions = [
    "jpg",
    "png",
    "jpeg",
    "webp",
    "gif",
    "mp4",
    "webm",
    "ogg",
  ];

  const dispatch = useAppDispatch();

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const files = Array.from(e.target.files);
    const maxSize = 5242880; // 5MB in bytes
    const maxFiles = 5;
    const validExtensions = ["image/png", "image/jpeg", "image/gif"];

    // Check if any file exceeds the maximum size
    if (files.some((file) => file.size > maxSize)) {
      Toast({
        message: "File exceeds maximum size, Please add files under 5MB",
        type: TOAST_CONSTANTS.WARNING,
      });
      return;
    }

    // Check if the number of files exceeds the limit
    if (files.length > maxFiles) {
      Toast({ message: "Files exceeded limit", type: TOAST_CONSTANTS.WARNING });
      return;
    }

    // Check if all files have valid extensions
    if (!files.every((file) => validExtensions.includes(file.type))) {
      Toast({ message: "Invalid File Type", type: TOAST_CONSTANTS.WARNING });
      return;
    }

    const filesToAdd: string[] = [];

    // Process valid files
    files.forEach((file) => {
      dispatch(
        uploadImage({
          params: {
            folder_name: `product/review`,
            file_name: file.name,
            file: file,
          },
          updatePropsValues: (value) => {
            filesToAdd.push(value);
          },
        })
      );
    });

    onChange && onChange(filesToAdd);
  };

  return (
    <div>
      <input
        multiple
        type="file"
        accept="image/*,video/*"
        onChange={handleFileUpload}
        {...props}
      />
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        {value &&
          value?.map((media, index) => (
            <div key={index} style={{ margin: "10px", position: "relative" }}>
              {media.type === "image" ? (
                <img
                  src={media.url}
                  alt={media.name}
                  className="upload-media-img"
                />
              ) : (
                <video
                  src={media.url}
                  controls
                  className="upload-media-video"
                />
              )}
              <Button
                className="upload-media-remove-btn"
                loading={false}
                type="button"
                onClick={() => {
                  deleteImage && deleteImage(media);
                }}
                text="&times;"
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export { FileUpload };
