import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  calculatePrice,
  customRevalidateTag,
  formatProduct,
  ICartItem,
  REQUEST_METHOD,
  STATUSES,
  to2Decimal,
} from "@/utils";
import { setCommonState } from "./commonSlice";
import { fetchShippingCost, setShippingCost } from "./orderSlice";

const initialState = {
  cartItemsCounter: 0,
  cartItems: [] as ICartItem[],
  currentCart: [] as ICartItem[],
  cart_status: STATUSES.IDLE as string,
  subTotal: 0,
  shipping: 0,
  tax: 0,
  total: 0,
};

export const addProduct = createAsyncThunk(
  "cart/addProduct",
  async (
    {
      product,
      db = false,
      onSuccess,
    }: { product: any; db?: boolean; onSuccess?: () => void },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      cart: { cartItems },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    const existingProductIndex = cartToUse.findIndex(
      (item: any) =>
        item.id === product.id && item.weight_id === product.weight_id
    );

    onSuccess && onSuccess();

    if (existingProductIndex !== -1) {
      if (db) {
        cartToUse[existingProductIndex].quantity =
          Number(product?.quantity) || 1;
      } else {
        const new_quantity =
          cartToUse[existingProductIndex].quantity +
          (Number(product?.quantity) > 1 ? Number(product?.quantity) : 1);

        cartToUse[existingProductIndex].quantity = new_quantity;

        cartToUse[existingProductIndex].payable_amount = to2Decimal(
          product.price * new_quantity
        );
      }

      return cartToUse;
    }

    return [
      {
        ...product,
        quantity: Number(product?.quantity) > 1 ? Number(product?.quantity) : 1,
      },
      ...cartToUse,
    ];
  }
);

export const updateQuantity = createAsyncThunk(
  "cart/updateQuantity",
  async (
    {
      index,
      quantity,
      db = false,
    }: { index: number; quantity: number; db?: boolean },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      cart: { cartItems },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    if (quantity === 0) {
      return cartToUse.filter((_: any, i: number) => i !== index);
    }

    if (index !== -1) {
      cartToUse[index].quantity = quantity;
      cartToUse[index].payable_amount = to2Decimal(
        cartToUse[index].price * quantity
      );

      return cartToUse;
    }

    return cartToUse;
  }
);

export const removeProduct = createAsyncThunk(
  "cart/removeProduct",
  async (
    { index }: { index: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      cart: { cartItems },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    return cartToUse.filter((_: any, i: number) => i !== index);
  }
);

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (
    {
      product_id,
      quantity,
      weight_id,
      onSuccess,
      index,
    }: {
      product_id: number;
      quantity: number;
      weight_id: number;
      onSuccess: () => void;
      index?: number;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `add-cartItem-${product_id}${index ? `-${index}` : ""}`,
      })
    );
    try {
      const response = await fetch("/api/cart", {
        method: REQUEST_METHOD.POST,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product_id: product_id,
          quantity: quantity,
          weight_id: weight_id,
        }),
      }).then((res) => res.json());

      const formattedProduct = formatProduct(response.result.product_details);

      const selectedWeight =
        formattedProduct.weights.find(
          (weight: any) => weight.id === response.result.weight_id
        )?.value ?? 0;

      const productData = {
        ...formattedProduct,
        price:
          formattedProduct.selling_uom === "pcs"
            ? formattedProduct.price
            : calculatePrice(1000, formattedProduct.price, selectedWeight),
      };

      const dataToAdd = {
        ...response.result,
        cart_id: response.result.id,
        quantity: response.result.quantity,
        weight_id: response.result.weight_id,
        weight: productData.weights.find(
          (weight: any) => weight.id === response.result.weight_id
        )?.value,
        payable_amount: productData.price * response.result.quantity,
        discount_value: 0,
        discount_code: "",
        discount_type: "flat",
        ...productData,
      };

      delete dataToAdd["product_details"];

      // customRevalidateTag("mini-cart");

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `add-cartItem-${product_id}`,
        })
      );

      // onSuccess && onSuccess();

      dispatch(addProduct({ product: dataToAdd, db: true, onSuccess }));

      return response.result;
    } catch (error) {
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: `add-cartItem-${product_id}`,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const removeCartItem = createAsyncThunk(
  "cart/removeCartItem",
  async (
    { cart_id }: { cart_id: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `delete-cartItem-${cart_id}`,
      })
    );

    const {
      cart: { cartItems },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    const existingProductIndex = cartToUse.findIndex(
      (item: any) => item.cart_id === cart_id
    );

    try {
      const response = await fetch(`/api/cart/${cart_id}/`, {
        method: REQUEST_METHOD.DELETE,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      dispatch(
        removeProduct({
          index: existingProductIndex,
        })
      );

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `delete-cartItem-${cart_id}`,
        })
      );

      customRevalidateTag("cart");

      // customRevalidateTag("mini-cart");
    } catch (error) {
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: `delete-cartItem-${cart_id}`,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateCartItem = createAsyncThunk(
  "cart/updateCartItem",
  async (
    { cart_id, quantity }: { cart_id: number; quantity: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      cart: { cartItems },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    const existingProductIndex = cartToUse.findIndex(
      (item: any) => item.cart_id === cart_id
    );

    if (quantity > 0) {
      try {
        const response = await fetch(`/api/cart/${cart_id}`, {
          method: REQUEST_METHOD.PUT,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ quantity: quantity }),
        }).then((res) => res.json());

        dispatch(
          updateQuantity({ index: existingProductIndex, quantity: quantity })
        );

        // customRevalidateTag("mini-cart");

        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      dispatch(removeCartItem({ cart_id: cart_id }));
    }
  }
);

export const setWholeCart = createAsyncThunk(
  "cart/setWholeCart",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setCommonState({ status: STATUSES.LOADING, type: "fetch-cart" }));
    dispatch(resetCartState());

    const cartToSet: ICartItem[] = [];

    try {
      const cartItems = await fetch(`/api/cart/`, {
        method: REQUEST_METHOD.GET,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      for (let i in cartItems.result.results) {
        try {
          const formattedProduct = formatProduct(
            cartItems.result.results[i].product_details
          );

          const selectedWeight =
            formattedProduct.weights.find(
              (weight: any) =>
                weight.id === cartItems.result.results[i].weight_id
            )?.value ?? 0;

          const productData = {
            ...formattedProduct,
            price:
              formattedProduct.selling_uom === "pcs"
                ? formattedProduct.price
                : calculatePrice(1000, formattedProduct.price, selectedWeight),
          };

          const dataToAdd = {
            ...cartItems.result.results[i],
            cart_id: cartItems.result.results[i].id,
            quantity: cartItems.result.results[i].quantity,
            weight_id: cartItems.result.results[i].weight_id,
            weight: productData.weights.find(
              (weight: any) =>
                weight.id === cartItems.result.results[i].weight_id
            )?.value,
            payable_amount:
              productData.price * cartItems.result.results[i].quantity,
            discount_value: 0,
            discount_code: "",
            discount_type: "flat",
            ...productData,
          };

          delete dataToAdd["product_details"];

          cartToSet.push(dataToAdd);
        } catch (cartItemError: any) {
          rejectWithValue(cartItemError.message);
        }
      }

      dispatch(setCommonState({ status: STATUSES.IDLE, type: "fetch-cart" }));
    } catch (error: any) {
      dispatch(setCommonState({ status: STATUSES.ERROR, type: "fetch-cart" }));

      rejectWithValue(error.message);
    } finally {
      dispatch(setCartItemsCounter(cartToSet?.length));
      return cartToSet;
    }
  }
);

export const clearCart = createAsyncThunk(
  "cart/clearCart",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `clear-cartItem`,
      })
    );
    try {
      const response = await fetch(`/api/cart/`, {
        method: REQUEST_METHOD.DELETE,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      dispatch(setShippingCost(0));

      dispatch(resetCartState());

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `clear-cartItem`,
        })
      );

      customRevalidateTag("cart");

      // customRevalidateTag("mini-cart");
    } catch (error) {
      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `clear-cartItem`,
        })
      );
      return rejectWithValue(error);
    }
  }
);

const updatePrices = (cartItems: any[]) => {
  const subTotal = to2Decimal(
    cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
  );
  const tax = to2Decimal(subTotal * 0.18);
  const total = to2Decimal(subTotal + tax);
  return { subTotal, tax, total };
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartItemsCounter: (state, action) => {
      state.cartItemsCounter = action.payload;
    },
    incrementCartItemsCounter: (state) => {
      state.cartItemsCounter += 1;
    },
    decrementCartItemsCounter: (state) => {
      state.cartItemsCounter -= 1;
    },
    resetCartState: (state) => {
      state.cartItems = [];
      state.cartItemsCounter = 0;
      state.subTotal = 0;
      state.shipping = 0;
      state.tax = 0;
      state.total = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setWholeCart.fulfilled, (state, action) => {
        state.cartItems = action.payload;
      })
      // .addCase(addToCart.fulfilled, (state, action) => {
      //   const cartIndex = state.cartItems.findIndex(
      //     (item) => item.id === action.payload.id
      //   );
      //   if (cartIndex === -1) {
      //     const productData = formatProduct(action.payload.product_details);

      //     const dataToAdd = {
      //       ...action.payload,
      //       cart_id: action.payload.id,
      //       quantity: action.payload.quantity,
      //       weight_id: action.payload.weight_id,
      //       weight: productData.weights.find(
      //         (weight: any) => weight.id === action.payload.weight_id
      //       )?.value,
      //       uom: "gm",
      //       ...productData,
      //     };

      //     delete dataToAdd["product_details"];
      //     state.cartItems = [...state.cartItems, dataToAdd];
      //   } else {
      //     state.cartItems[cartIndex].quantity = action.payload.quantity;
      //   }
      // })
      // .addCase(updateCartItem.fulfilled, (state, action) => {
      //   const cartIndex = state.cartItems.findIndex(
      //     (item) => item.cart_id === action.meta.arg.cart_id
      //   );
      //   state.cartItems[cartIndex].quantity = action.meta.arg.quantity;
      // })
      // .addCase(removeCartItem.fulfilled, (state, action) => {
      //   state.cartItems = state.cartItems.filter(
      //     (item) => item.cart_id !== action.meta.arg.cart_id
      //   );
      // })
      .addMatcher(
        isAnyOf(
          addProduct.fulfilled,
          removeProduct.fulfilled,
          updateQuantity.fulfilled
        ),
        (state, action) => {
          state.cartItems = action.payload;
          const { subTotal, tax, total } = updatePrices(action.payload);
          state.subTotal = subTotal;
          state.tax = tax;
          state.total = total;
        }
      )
      .addMatcher(
        isAnyOf(
          addToCart.pending,
          updateCartItem.pending,
          removeCartItem.pending,
          clearCart.pending
        ),
        (state) => {
          state.cart_status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(
          addToCart.fulfilled,
          updateCartItem.fulfilled,
          removeCartItem.fulfilled,
          setWholeCart.fulfilled,
          clearCart.fulfilled
        ),
        (state) => {
          // customRevalidateTag("cart");
          // customRevalidateTag("mini-cart");
          state.cart_status = STATUSES.IDLE;
          const { subTotal, tax, total } = updatePrices(state.cartItems);

          state.subTotal = subTotal;
          state.tax = tax;
          state.total = total;
        }
      );
  },
});

export const {
  setCartItemsCounter,
  incrementCartItemsCounter,
  decrementCartItemsCounter,
  resetCartState,
} = cartSlice.actions;

export default cartSlice.reducer;
