"use client";

import React, { useEffect, useRef, useState } from "react";
import { CartSummary } from "../Cart";
import { OrderTable } from "./OrderTable";
import {
  fetchShippingCost,
  resetCartState,
  setLastSelectedAddress,
  setShippingCost,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  BUTTON_CONSTANTS,
  calculateAllValues,
  calculateTax,
  IAddOrder,
  IAddOrderItem,
  IRazorpayInput,
  IAddress,
  ICartItem,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
  to2Decimal,
  IRazorpayResponse,
  IExtendedRazorpayResponse,
  addPaymentMapping,
  onPaymentFailure,
  generateRazorpayOptions,
  getPaymentModeId,
  Toast,
  TOAST_CONSTANTS,
  STATUSES,
  canPlaceOrder,
  constuctAddress,
} from "@/utils";
import { UserAddressList } from "./UserAddressList";
import { Button } from "@/components/core-components";
import { redirect, useRouter } from "next/navigation";
import Script from "next/script";
import parse from "html-react-parser";

const Checkout = ({
  cartItems: DBCartItems,
  addresses,
}: {
  cartItems: ICartItem[];
  addresses: IAddress[];
}) => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector((state) => state.common);

  const { proceedToCheckout } = useAppSelector((state) => state.order);

  const { lastSelectedAddress, shippingCost } = useAppSelector(
    (state) => state.order
  );

  const router = useRouter();

  const [sameAddress, setSameAddress] = useState<boolean>(true);

  const payment_mode = useRef(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);

  const [addressAccordion, setAddressAccordion] = useState({
    shipping: {
      state: true,
      id:
        lastSelectedAddress ||
        addresses?.find((item) => item.set_as_default === 1)?.id ||
        addresses?.[0]?.id ||
        0,
    },
    billing: {
      state: false,
      id:
        lastSelectedAddress ||
        addresses?.find((item) => item.set_as_default === 1)?.id ||
        addresses?.[0]?.id ||
        0,
    },
  });

  const canProceedToCheckout = canPlaceOrder(DBCartItems);

  const prepareCart = (cartItems: ICartItem[]) => {
    const { total_amount, sub_total, total_items, round_off } =
      calculateAllValues(cartItems);

    const bill_discount = 0;

    const shipping_address = constuctAddress(
      addresses?.find((address) => address.id === addressAccordion.shipping?.id)
    );

    const billing_address = constuctAddress(
      addresses?.find((address) => address.id === addressAccordion.billing?.id)
    );

    const new_cartItems = cartItems.map((cartItem: ICartItem) => {
      const { new_payable_amount, bill_discount_per_item, cess, gst } =
        calculateTax(
          cartItem.payable_amount,
          cartItem.tax,
          cartItem.cess,
          total_amount,
          bill_discount //bill_discount
        );

      const new_cartItem = {
        product: cartItem.id,
        batch: cartItem.batch_id,
        product_weight: cartItem.weight_id,
        quantity: cartItem.quantity,
        total_amount: to2Decimal(new_payable_amount - bill_discount_per_item),
        payable_amount: new_payable_amount,
        discount_value: bill_discount_per_item,
        discount_code: "",
        discount_type: "flat",
        cess: cess,
        cgst: gst / 2,
        igst: 0,
        sgst: gst / 2,
        unit_price: to2Decimal(
          (new_payable_amount - bill_discount_per_item) / cartItem.quantity
        ),
        total_discount: bill_discount_per_item,
        total_tax: to2Decimal(gst + cess),
      };

      return new_cartItem;
    });

    return {
      bill_amount: sub_total,
      total_shipping: shippingCost || 0,
      payable_amount: to2Decimal(total_amount - bill_discount + shippingCost),
      discount_amount: bill_discount,
      discount_code: "",
      discount_type: "flat",
      total_quantity: total_items,
      roundoff: round_off,
      remarks: "",
      total_discount: bill_discount,
      total_tax: to2Decimal(
        new_cartItems.reduce(
          (acc, item: IAddOrderItem) => acc + item.total_tax,
          0
        )
      ),
      products: new_cartItems,
      addresses: [
        {
          ...shipping_address,
          address_type: "shipping",
        },
        {
          ...billing_address,
          address_type: "billing",
        },
      ],
    };
  };

  const placeRazorpayOrder = async (order_response: IRazorpayInput) => {
    try {
      const razorpay_order_response: IRazorpayResponse = await fetch(
        "/api/razorpay/",
        {
          method: "POST",
          headers: {
            "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
          },
          body: JSON.stringify(order_response),
        }
      ).then((res) => res.json());

      const newPaymentMapping = await addPaymentMapping({
        razorpay_order_id: razorpay_order_response.id,
        order_id: razorpay_order_response.notes.order_id,
      });

      return {
        ...razorpay_order_response,
        payment_mapping_id: newPaymentMapping,
      };
    } catch (error: any) {
      setLoading(false);
      Toast({ message: error.message, type: TOAST_CONSTANTS.ERROR });
      return null;
    }
  };

  const placeOrder = async () => {
    setLoading(true);

    const order_data: IAddOrder = prepareCart(DBCartItems);

    try {
      const order_response = await fetch("/api/order/", {
        method: REQUEST_METHOD.POST,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
        body: JSON.stringify(order_data),
      }).then((res) => res.json());

      if (order_response.error) {
        setLoading(false);
        Toast({ message: order_response.error, type: TOAST_CONSTANTS.ERROR });
        return;
      }

      dispatch(resetCartState());

      const order_details = {
        order_id: order_response.result.id,
        store_id: order_response.result.store,
        invoice_code: order_response.result.invoice_code,
        payable_amount: order_response.result.payable_amount,
        customer_details: {
          name: order_response.result.customer.name,
          contact_number: order_response.result.customer.contact_number,
          email: order_response.result.customer.email,
        },
      };

      const razorpay_order_response: IExtendedRazorpayResponse | null =
        await placeRazorpayOrder(order_details);

      if (razorpay_order_response) {
        const options = generateRazorpayOptions(
          {
            amount: razorpay_order_response.amount,
            id: razorpay_order_response.id,
            notes: razorpay_order_response.notes,
            payment_mapping_id: razorpay_order_response.payment_mapping_id,
          },
          paymentFailure,
          router,
          dispatch,
          false,
          setLoading
        );

        const paymentObject = new window.Razorpay(options);

        paymentObject.on("payment.submit", async function (response: any) {
          payment_mode.current = response.method;
        });

        paymentObject.on("payment.failed", async function (response: any) {
          const orderDetail = await fetch(
            `/api/razorpay/order/${response.error.metadata.order_id}/`
          ).then((res) => res.json());

          await onPaymentFailure({
            paymentFailure: orderDetail.attempts > 1,
            error_response: response,
            razorpay_order_response: razorpay_order_response,
            payment_mode: payment_mode.current || "",
          });

          setPaymentFailure(true);
        });

        paymentObject.open();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(fetchShippingCost({ cartItems: DBCartItems }));
    return () => {
      dispatch(setLastSelectedAddress(addressAccordion.shipping.id));
      dispatch(setShippingCost(0));
    };
  }, [addressAccordion.billing.id, addressAccordion.shipping.id]);

  return (
    <>
      <Script
        id="razorpay-checkout-js"
        src={STATIC_CONSTANTS.RAZORPAY_CONSTANTS.CHECKOUT_URL}
      />
      <section className="">
        <div className="container pt-30">
          <div className="row">
            <div className="col-lg-8 mb-15">
              <div className="section-title style-2 mb-0">
                <h3>Checkout</h3>
              </div>
            </div>
            {/* <div className="col-lg-4 mb-15 text-end">
              <Button
                className="btn btn-sm btn-fill-out btn-block"
                text={"Place Order"}
                disabled={
                  DBCartItems?.length === 0 ||
                  (state.status === STATUSES.LOADING &&
                    state.type === "shipping-cost")
                }
                loading={loading}
                type={BUTTON_CONSTANTS.BUTTON}
                onClick={placeOrder}
              />
            </div> */}
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="shipping-address">
                <UserAddressList
                  type="shipping"
                  addresses={addresses}
                  title="Shipping Address"
                  address={addressAccordion}
                  setAddress={setAddressAccordion}
                  setSameAddress={setSameAddress}
                />
              </div>

              {/* <div className="divider mt-10 mb-10"></div> */}

              <div className="custome-checkbox mb-20">
                <input
                  type="checkbox"
                  className="form-check-input text-xs"
                  name="isBillingSame"
                  value="isBillingSame"
                  checked={sameAddress}
                  onClick={() => {
                    setAddressAccordion({
                      shipping: {
                        ...addressAccordion.shipping,
                        state: false,
                      },
                      billing: {
                        state: true,
                        id:
                          lastSelectedAddress ||
                          addresses?.find((item) => item.set_as_default === 1)
                            ?.id ||
                          addresses?.[0]?.id ||
                          0,
                      },
                    });
                    setSameAddress(!sameAddress);
                  }}
                  id="isBillingSame"
                />
                <label
                  className="form-check-label font-xl user-select-none"
                  htmlFor="isBillingSame"
                >
                  Same as Shipping Address
                </label>
              </div>

              {!sameAddress && (
                <div className="billing-address">
                  <UserAddressList
                    type="billing"
                    addresses={addresses}
                    title="Billing Address"
                    address={addressAccordion}
                    setAddress={setAddressAccordion}
                    setSameAddress={setSameAddress}
                  />
                </div>
              )}
            </div>
            <div className="col-lg-5">
              <div className="border p-15 cart-totals ml-30 mb-30">
                <CartSummary
                  title="Order Summary"
                  cartItems={DBCartItems}
                  shipping_cost={shippingCost}
                  showTotalItems={true}
                />
                <OrderTable cartItems={DBCartItems} />
                {/* <PaymentMethod /> */}
                <div className="text-xs text-start pb-10">
                  {parse(
                    `By placing your order, you agree to Avarya's <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    <Button
                      className="btn btn-sm w-100 btn-fill-out btn-block"
                      text={"Place Order"}
                      disabled={
                        DBCartItems?.length === 0 ||
                        !(shippingCost > 0) ||
                        !canProceedToCheckout ||
                        proceedToCheckout ||
                        (state.status === STATUSES.LOADING &&
                          state.type === "shipping-cost")
                      }
                      loading={loading}
                      type={BUTTON_CONSTANTS.BUTTON}
                      onClick={() => {
                        if (canProceedToCheckout) {
                          placeOrder();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Checkout };
