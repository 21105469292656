// Widget optimizations

import {
  apiRequest,
  constructTasteOptions,
  formatProduct,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
  transformDataToBannerConfig,
  transformDataToListingConfig,
  transformDataToMultipleListingConfig,
  transformDataToProductDealsConfig,
  WIDGET_CONSTANTS,
} from "@/utils";
import { notFound } from "next/navigation";
import {
  BannerWidget,
  Deals,
  ListingWidet,
  MulipleListing,
} from "./group-components";

import parse from "html-react-parser";
import React, { Suspense } from "react";
import { StructuredData } from "./StructuredData";

const Widget = ({
  pageData,
  is_logged_in = false,
  nonce,
}: {
  pageData?: any;
  is_logged_in?: boolean;
  nonce: string | null;
}) => {
  if (!pageData || (Array.isArray(pageData) && pageData.length === 0)) {
    return notFound();
  }

  const fetchListingData = async (
    listingConfig: any,
    containsCategorySubCategory: boolean
  ) => {
    const endpoint = `${STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL}${
      listingConfig.fetchUrl
    }${containsCategorySubCategory ? "&" : "?"}page=1&limit=${
      containsCategorySubCategory ? 24 : 12
    }`;

    const response = is_logged_in
      ? await apiRequest({
          url: endpoint,
          method: REQUEST_METHOD.GET,
          additionalHeaders: { "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON },
          tags: ["page-data"],
        }).then((res) => res.json())
      : await fetch(endpoint, {
          method: REQUEST_METHOD.GET,
          headers: { "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON },
          next: { tags: ["page-data"] },
        }).then((res) => res.json());

    if (response?.result?.product_list?.results?.length > 0) {
      listingConfig.content =
        response.result.product_list.results.map(formatProduct);
      listingConfig.totalPages = Math.ceil(
        response.result.product_list.count /
          response.result.product_list.page_size
      );
      listingConfig.count = response.result.product_list.count;
      listingConfig.taste = constructTasteOptions(
        response.result.product_taste_list
      );
    }
  };

  const renderWidget = async (widget: any, index: number) => {
    const commonProps = {
      widgetIndex: index,
      pageDetails: {
        display_name: pageData.display_name,
        short_summary: pageData.short_summary,
      },
    };

    switch (widget.widget_type) {
      case WIDGET_CONSTANTS.BANNER:
        const bannerConfig = await transformDataToBannerConfig(widget);

        return <BannerWidget config={bannerConfig} {...commonProps} />;

      case WIDGET_CONSTANTS.LISTING:
        let listingConfig = transformDataToListingConfig(widget);

        if (listingConfig.content?.length === 0) {
          const containsCategorySubCategory =
            listingConfig.fetchUrl.includes("category") ||
            listingConfig.fetchUrl.includes("subcategory");

          await fetchListingData(listingConfig, containsCategorySubCategory);
        }

        return (
          <ListingWidet
            config={listingConfig}
            type={widget.type}
            noOfWidget={pageData?.content?.length}
            {...commonProps}
          />
        );

      case WIDGET_CONSTANTS.MULTIPLE_LISTING:
        return (
          <MulipleListing
            config={transformDataToMultipleListingConfig(widget)}
            {...commonProps}
          />
        );

      case WIDGET_CONSTANTS.DEALS:
        return (
          <Deals
            config={transformDataToProductDealsConfig(widget)}
            {...commonProps}
          />
        );

      case WIDGET_CONSTANTS.STATIC:
        return parse(widget?.content?.[0]?.value);

      default:
        return notFound();
    }
  };

  return (
    <React.Fragment key={pageData?.meta_slug || pageData?.id}>
      <StructuredData nonce={nonce} />
      {pageData.content.map((item: any, index: number) =>
        renderWidget(
          {
            page_id: pageData.id,
            id: item.id,
            widget_id: item.widget.id,
            widget_type: item.widget.widget_type,
            content: item.content,
            type: item.pages
              ? "static"
              : item.product_category_cms
              ? "category"
              : item.product_subcategory_cms
              ? "sub_category"
              : "product",
          },
          index
        )
      )}
    </React.Fragment>
  );
};

export { Widget };
