"use client";

import { Slider } from "@/components/core-components";
import { SliderProps } from "@/utils";
import { Fragment, useCallback, useMemo } from "react";

const HorizontalListing = ({
  last = false,
  type,
  title,
  prevBtn,
  nextBtn,
  btnClassName,
  parentClassName,
  sliderClassName,
  sliderId,
  slidesPerView,
  spaceBetween,
  autoplay,
  children,
  containerClassName = "container",
  pagination,
  onNavigationNext,
  toRender = true,
  dataLength,
  centered = false,
}: SliderProps) => {
  const renderTitle = () => {
    return (
      <div className="section-title">
        <div className="title">
          <h3>{title}</h3>
        </div>
      </div>
    );
  };

  const renderSlidesHorizontally = useCallback(() => {
    return (
      <div className={`${sliderClassName ? sliderClassName : ""}`}>
        <div
          className={`${sliderId ? sliderId : ""}`}
          id={sliderId?.replace(/\s/g, "-")}
        >
          <Slider
            type={type}
            prevBtn={prevBtn}
            nextBtn={nextBtn}
            btnClassName={btnClassName}
            slidesPerView={slidesPerView}
            spaceBetween={spaceBetween}
            autoplay={autoplay}
            pagination={pagination}
            onNavigationNext={onNavigationNext}
            centered={centered}
            toRender={toRender}
            dataLength={dataLength}
          >
            {children}
          </Slider>
        </div>
      </div>
    );
  }, [dataLength]);

  return (
    <section
      className={`${!toRender ? "visually-hidden" : ""} ${
        parentClassName ? parentClassName : ""
      }`}
    >
      <div className={`${containerClassName ? containerClassName : ""}`}>
        <div className="row">
          <div className="col-lg-12">
            {title && renderTitle()}
            {renderSlidesHorizontally()}
             {!last ? <div className={`section-border-top`} /> : null}
          </div>
        </div>
      </div>

    </section>
  );
};

export { HorizontalListing };
