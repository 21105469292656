"use client";

import { Slider } from "@/components/core-components";
import React, { Suspense } from "react";
import { BannerSlide } from "./BannerSlide";
import { SwiperSlide } from "swiper/react";
import { GeneralSliderProps } from "@/utils";

const BannerSlider = ({
  data,
  prevBtn,
  nextBtn,
  className,
  btnClassName,
  slideClassName,
  widgetIndex,
}: GeneralSliderProps) => {
  return (
    <Slider
      prevBtn={prevBtn}
      nextBtn={nextBtn}
      className={`${className || ""}`}
      btnClassName={`${btnClassName || ""}`}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      slidesPerView={1}
      breakpoints={null}
      pagination={{
        clickable: true,
      }}
    >
      {data?.length > 0 &&
        data?.map((item: any, index: number) => (
          <SwiperSlide
            className={`${slideClassName || ""}`}
            key={index + 1}
            virtualIndex={index + 1}
          >
            <Suspense
              fallback={
                <section>
                  <div className="full-width-banner">
                    <span
                      className="skeleton skeleton-text w-100 h-100"
                      style={{ maxWidth: "1600px", maxHeight: "600px" }}
                    ></span>
                  </div>
                </section>
              }
            >
              <BannerSlide
                item={item}
                index={index + 1}
                widgetIndex={widgetIndex || 0}
              />
            </Suspense>
          </SwiperSlide>
        ))}
    </Slider>
  );
};

export { BannerSlider };
