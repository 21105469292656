"use client";

import { ProductItem } from "./ProductItem";
import { HorizontalListing } from "../HorizontalListing";
import { SwiperSlide } from "swiper/react";
import { capitalizeFirstLetter, GeneralSliderProps, IProduct } from "@/utils";
import { Fragment, useMemo } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import parse from "html-react-parser";
import { useAppSelector } from "@/store";
import { STATUSES } from "../../../../utils/constants";

const ProductShimmerItem = () => {
  return (
    <div className={`product-cart-wrap mb-18 `}>
      <div className="product-img-action-wrap">
        <div className="product-img product-img-zoom mx-1">
          <span
            className="skeleton skeleton-text default-img"
            style={{
              display: "block",
              width: "200px",
              height: "200px",
            }}
          ></span>
        </div>
      </div>

      <div className={`product-content-wrap `}>
        <h2>
          <span className="skeleton skeleton-text d-block w-100"></span>
        </h2>

        <div className="d-flex flex-row justify-content-between align-items-center gap-2 my-2">
          <div className="col-2">
            <h2>
              <span className="skeleton skeleton-text d-block w-100"></span>
            </h2>
          </div>
          <div className="offset-4 col-5">
            <h2>
              <span className="skeleton skeleton-text d-block w-100"></span>
            </h2>
          </div>
        </div>

        <h2>
          <span className="skeleton skeleton-text d-block w-100"></span>
        </h2>

        <div className="product-card-bottom mt-0 flex-column">
          <div className="add-cart d-flex gap-2 mt-5">
            <a className="add w-100 text-center">
              <i className="fi-rs-shopping-cart mr-5"></i>
              Add to Cart
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductListing = ({
  type = "",
  products,
  deleteBtn,
  hasDesc = false,
  largeCard = false,
  widgetIndex,
}: {
  type: string;
  products: IProduct[];
  deleteBtn?: (value: any) => void;
  largeCard?: boolean;
  hasDesc?: boolean;
  widgetIndex: number;
}) => {
  const { state } = useAppSelector((state) => state.common);

  const paths = usePathname();

  const currentPage = paths
    .split("/")
    .filter((path) => path)
    .splice(-1)[0];

  const noProductsFoundMessage = (type: string) => {
    switch (type) {
      case "category":
      case "sub_category":
        return `We couldn't find any products in the '${capitalizeFirstLetter(
          currentPage
        )}' category at the moment.<br/>Please explore our other categories or check back later.`;

      default:
        return "No Products Found";
    }
  };

  const noProductsFoundTitle = (type: string) => {
    switch (type) {
      case "category":
      case "sub_category":
        return `No Products Found in Category`;

      default:
        return "No Products Found";
    }
  };

  return (
    <>
      {
        products?.length > 0
          ? products?.map((product, i) => (
              <div
                className={`col-md-4 col-sm-6 col-6 col-lg-${
                  deleteBtn ? "1-5" : largeCard ? "3" : "2"
                }`}
                key={i}
              >
                <ProductItem
                  type={type}
                  widgetIndex={widgetIndex}
                  index={i}
                  product={product}
                  hasDesc={hasDesc}
                  deleteBtn={deleteBtn}
                  largeCard={largeCard}
                />
              </div>
            ))
          : Array.from({ length: 12 }).map((_, index: number) => (
              <div
                className={`col-md-4 col-sm-6 col-6 col-lg-${
                  deleteBtn ? "1-5" : largeCard ? "3" : "2"
                }`}
                key={index}
              >
                <ProductShimmerItem />
              </div>
            ))
        // ) :(
        //   <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
        //     <p className="mb-20">
        //       <Image
        //         width={500}
        //         height={200}
        //         src="/assets/imgs/page/page-404.png"
        //         alt="not-found"
        //         className="hover-up"
        //       />
        //     </p>
        //     <h1 className="display-5 mb-30">{noProductsFoundTitle(type)}</h1>
        //     <p className="font-lg text-grey-700 mb-30">
        //       {parse(noProductsFoundMessage(type))}
        //     </p>
        //     <Link
        //       className="btn btn-default submit-auto-width font-xs hover-up"
        //       href="/"
        //     >
        //       <i className="fi-rs-home ml-0 mr-5"></i> Back To Home Page
        //     </Link>
        //   </div>
        // )
      }
    </>
  );
};

const ProductHorizontalListing = ({
  data: initialData,
  title,
  prevBtn,
  nextBtn,
  parentClassName,
  btnClassName,
  sliderClassName,
  sliderId,
  slidesPerView = 6,
  spaceBetween,
  autoplay,
  hasDesc = false,
  widgetIndex,
  type,
  lengthToRender = 0,
  centered = false,
  onNavigationNext,
}: GeneralSliderProps) => {
  const renderProducts = useMemo(() => {
    return initialData.map((item: any, index: number) => (
      <Fragment key={index}>
        <SwiperSlide className={`product-${index + 1}`} virtualIndex={index}>
          <ProductItem
            widgetIndex={widgetIndex || 0}
            product={item}
            hasDesc={hasDesc}
            index={index}
          />
        </SwiperSlide>

        {!widgetIndex &&
        initialData.length % 6 === 0 &&
        initialData.length - 1 === index ? (
          <SwiperSlide
            className={`product-${index + 1}`}
            virtualIndex={index + 1}
          >
            <div className="h-100 d-flex flex-row justify-content-center align-items-center">
              <span
                className="text-primary spinner-border align-self-center"
                aria-hidden="true"
              />
            </div>
          </SwiperSlide>
        ) : null}
      </Fragment>
    ));
  }, [initialData.length, initialData.length % 6 === 0]);

  return (
    <HorizontalListing
      type={type}
      autoplay={autoplay}
      title={title}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      prevBtn={prevBtn}
      nextBtn={nextBtn}
      parentClassName={parentClassName}
      btnClassName={btnClassName}
      sliderClassName={sliderClassName}
      sliderId={sliderId}
      onNavigationNext={onNavigationNext}
      centered={centered}
      dataLength={initialData.length}
      toRender={initialData.length >= lengthToRender}
    >
      {initialData.length >= lengthToRender ? renderProducts : null}
    </HorizontalListing>
  );
};

export { ProductListing, ProductHorizontalListing };
