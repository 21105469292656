"use client";

import { useEffect } from "react";
import { ReviewList } from "./ReviewList";
import { fetchProductReviews, useAppDispatch, useAppSelector } from "@/store";
import { getLastPathSegment, IReviewData } from "@/utils";
import { usePathname } from "next/navigation";

const ProductReviews = ({
  reviews,
  product_id,
  modalData = { state: false, data: null },
}: {
  reviews?: IReviewData;
  product_id?: number;
  modalData?: { state: boolean; data: any };
}) => {
  const dispatch = useAppDispatch();

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { productReviews, filter } = useAppSelector((state) => state.common);

  const is_logged_in = logged_in_user || access_token;

  const addReviewToProduct = product_id || modalData.data.product_id;

  const paths = usePathname();

  const reviewsToRender: IReviewData =
    reviews && reviews.review_list?.length > 0
      ? {
          ...reviews,
          review_list: reviews.review_list.slice(0, 3),
        }
      : productReviews && productReviews.review_list?.length > 0
      ? productReviews
      : {
          average_rating: 0,
          total_pages: 0,
          review_list: [],
          review_statistics: [],
        };

  useEffect(() => {
    if (modalData.state) {
      dispatch(
        fetchProductReviews({
          filters: filter,
          url: `/api/reviews/?query=${getLastPathSegment(paths)}`,
        })
      );
    }
  }, []);

  return (
    <section
      className={`section-padding-12 ${
        !is_logged_in &&
        ((reviews && reviews.review_list?.length === 0) ||
          productReviews.review_list?.length === 0)
          ? "visually-hidden"
          : ""
      }`}
    >
      <div className="container">
        <div className="row pb-0">
          <div className="col-12">
            <h3
              className="section-title style-1 mb-0 pb-10"
              id="listing-container"
            >
              Customer Reviews
            </h3>
          </div>
          <div className="col-12">
            <ReviewList
              reviews={reviewsToRender}
              product_id={addReviewToProduct}
              isModal={reviews === undefined}
              showSeeMore={
                (reviews?.review_list && reviews?.review_list?.length > 3) ||
                false
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export { ProductReviews };
