import {
  IAddress,
  IProfileDetails,
  STATUSES,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";

const initialState = {
  profile_details: {} as IProfileDetails,
  orders: [] as any[],
  addresses: [] as IAddress[],
  account_status: STATUSES.IDLE as string,
  account_error: null as string | null,
};

export const fetchProfileDetails = createAsyncThunk(
  "account/fetchProfileDetails",
  async ({ id }: TGetParams, { dispatch, rejectWithValue }) => {
    try {
      const response = {
        result: {
          first_name: "John",
          last_name: "Doe",
          email: "abc@xyz.com",
          mobile_no: "1234567890",
        },
      };
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAddresses = createAsyncThunk(
  "account/fetchAddresses",
  async ({ active }: TGetParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch(`/api/account/addresses?active=${active}`);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewAddress = createAsyncThunk(
  "account/addNewAddress",
  async (
    {
      addressParams,
      actions,
    }: {
      addressParams: any;
      actions: FormikHelpers<any>;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = {
        result: addressParams,
      };
      actions.resetForm();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingAddress = createAsyncThunk(
  "account/updateExistingAddress",
  async (
    {
      id,
      addressParams,
      actions,
    }: {
      id: number;
      addressParams: any;
      actions: FormikHelpers<any>;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = { result: addressParams };
      actions.resetForm();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    resetAccountState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProfileDetails.fulfilled, (state, action: any) => {
        state.profile_details = action.payload.result;
      })
      .addCase(addNewAddress.fulfilled, (state, action: any) => {
        state.addresses.unshift(action.payload.result);
        state.addresses = state.addresses.slice(0, 10);
      })
      .addCase(updateExistingAddress.fulfilled, (state, action: any) => {
        // Update the existing address in the addresses array
        const index = state.addresses.findIndex(
          (address) => address.id === action.payload.result.id
        );
        if (index !== -1) {
          state.addresses[index] = action.payload.result;
        }
      })

      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.account_status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(addNewAddress.fulfilled, updateExistingAddress.fulfilled),
        (_, action: any) => {
          Toast({
            message: action.payload.message,
            type: TOAST_CONSTANTS.SUCCESS,
          });
        }
      )
      .addMatcher(
        isAnyOf(
          fetchProfileDetails.pending,
          addNewAddress.pending,
          updateExistingAddress.pending
        ),
        (state) => {
          state.account_status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.account_status = STATUSES.ERROR;
          state.account_error = action.payload;
          Toast({ message: action.payload, type: TOAST_CONSTANTS.ERROR });
        }
      );
  },
});

export const { resetAccountState } = accountSlice.actions;

export default accountSlice.reducer;
