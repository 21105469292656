"use client";

import { ProductItem } from "..";

const DealsList = ({
  title,
  data,
  widgetIndex,
}: {
  title: string;
  data?: any[];
  widgetIndex: number;
}) => {
  return (
    <section className="section-padding-12 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title" data-wow-delay="0">
              <h3 className="">{title}</h3>
            </div>
            <div className="row">
              {data && data.length > 0
                ? data?.map((product: any, i: number) => (
                    <div className="col-xl-3 col-lg-4 col-md-6 col-6" key={i}>
                      <ProductItem
                        widgetIndex={widgetIndex}
                        index={i}
                        product={product}
                        hasDesc={false}
                        largeCard={false}
                      />
                    </div>
                  ))
                : null}
            </div>
            <div className={`section-border-top`} />
          </div>
        </div>
      </div>
    </section>
  );
};

export { DealsList };
